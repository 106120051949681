import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"

const MotherDeStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 10px 20px 7px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, #a5fab7, #00c4c1, #50b5d5);
  color: #262626;
  position: relative;

  @media (max-width: 550px) {
    padding: 5px 0px 2px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Text = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: "GothamLight";
  letter-spacing: 0.5px;

  span {
    font-size: 25px;
    line-height: 28px;
    font-family: "Gotham Book";
    font-weight: bold;

    @media (max-width: 850px) {
      font-size: 24px;
    }
  }

  strong {
    font-family: "Gotham Book";
    font-size: 21px;
    line-height: 24px;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const MobileText = styled.div`
  font-size: 16px;
  font-family: "GothamLight";
  letter-spacing: 0.5px;
  display: none;
  color: #262626;
  cursor: pointer;
  line-height: 1;

  @media (max-width: 767px) {
    display: inline;
  }

  @media (max-width: 550px) {
    font-size: 13px;
    line-height: 16px;
  }

  @media (max-width: 360px) {
    font-size: 12px;
  }

  span {
    font-family: "Gotham Book";
    font-weight: bold;
    font-size: 25px;
    line-height: 28px;

    @media (max-width: 850px) {
      font-size: 24px;
    }

    @media (max-width: 550px) {
      font-size: 18px;
      line-height: 20px;
    }

    @media (max-width: 480px) {
      font-size: 13px;
      line-height: 16px;
    }

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }

  strong {
    font-size: 21px;
    line-height: 24px;
    font-family: "Gotham Book";
    font-weight: bold;

    @media (max-width: 550px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 480px) {
      font-size: 13px;
      line-height: 16px;
    }

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }

  :hover {
    color: #262626;
  }
`

const Button = styled(Link)`
  border-radius: 5px;
  color: #262626;
  font-size: 16px;
  font-family: "Gotham Book";
  margin-top: 5px;
  margin-left: 20px;
  line-height: 22px;
  display: inline-block;
  // box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    display: none;
  }

  :hover {
    color: #262626;
  }
`

const MobileButton = styled.div`
  font-size: 11px;
  font-weight: bold;
  font-family: "GothamLight";
  color: #262626;
  cursor: pointer;
  display: inline;
  margin: 0px 0px 0px 5px;

  :hover {
    color: #262626;
  }
`

const MotherDeStickyBanner = ({ intl }) => {
  var ButtonHide = false
  var regex = /(shop|panier|cart|lyneup|lynefit|lynehome)/

  if (isBrowser) {
    ButtonHide = regex.test(window.location.pathname)
  }
  return (
    <MotherDeStickyBannerWrapper>
      <Container>
        {!ButtonHide && (
          <LinkBox to="/shop/lyneup-2b/">
            <LinkButtonTitle>{"Jetzt kaufen >"}</LinkButtonTitle>
          </LinkBox>
        )}
        <Text>
          <span>SONDERAKTION: </span>
          <span>-40%</span> auf das zweite Produkt
        </Text>
        <MobileText>
          <span>SONDERAKTION: </span>
          <span>-40%</span> auf das zweite Produkt
        </MobileText>
        {!ButtonHide && (
          <Button to="/shop/lyneup-2b/">{"Jetzt kaufen >"}</Button>
        )}
        {!ButtonHide && <MobileButton>{" >>"}</MobileButton>}
      </Container>
    </MotherDeStickyBannerWrapper>
  )
}

export default injectIntl(MotherDeStickyBanner)
