import { isBrowser } from "../context/ApolloContext"
const Multipassify = isBrowser ? require("multipassify") : {}

export const MultipassURL = customerData => {
  let secret =
    process.env.GATSBY_MULTIPASS_SECRET || "b527ed587db6ed072adb1707b633bc09"
  //   let domain = "percko-test.myshopify.com"
  let domain = process.env.GATSBY_SHOPIFY_URL || "checkout.percko.com"
  // Construct the Multipassify encoder
  var multipassify = new Multipassify(secret)
  // Encode a Multipass token
  var token = multipassify.encode(customerData)
  // Generate a Shopify multipass URL to your shop
  var url = multipassify.generateUrl(customerData, domain)
  return url
}
