import ApolloClient from "apollo-boost"
// import { getAccessToken } from "../utils/auth"

export const isBrowser = typeof window !== "undefined"

const domain = process.env.GATSBY_SHOPIFY_URL || "checkout.percko.com"
const accessToken =
  process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN ||
  "91873abe829c1a8e45b1e9015663e7e6"

export const apolloClient = isBrowser
  ? new ApolloClient({
      uri: `https://${domain}/api/2023-07/graphql.json`,
      headers: {
        "X-Shopify-Storefront-Access-Token": accessToken,
        Accept: "application/json",
      },
    })
  : {}
