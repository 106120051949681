import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"

import CountryDetectionSelector from "./CountryDetectionSelector"
import { withCountryContext } from "../../../context/CountryContext"

const CountryDetectionPopinWrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(
    145deg,
    rgb(93, 185, 188) 10%,
    rgb(42, 161, 169) 100%
  );
  position: relative;
  z-index: 3;

  @media (max-width: 850px) {
    padding: 10px 20px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

const Text = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  font-family: "GothamLight";
  flex: 1;
`

const CloseButton = styled.a`
  background: none;
  color: #fff;
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;
  position: absolute;

  :hover {
    color: #fff;
  }

  @media (max-width: 850px) {
    position: absolute;
  }
`

const CountryDetectionPopin = ({
  intl,
  hideBanner,
  closeBanner,
  countryContext,
}) => {
  let bannerText = [
    {
      locale: "fr",
      messages:
        "Choisissez un pays pour afficher le contenu adapté à votre emplacement géographique et achetez en ligne. Livraison gratuite dans le monde entier. Paiement sécurisé.",
    },
    {
      locale: "en",
      messages:
        "Select a country to display adapted content for your geographical area and purchase online. Free worldwide delivery . Secure payment.",
    },
    {
      locale: "de",
      messages:
        "Wählen Sie ein Land aus, um den auf Ihren geografischen Standort angepassten Inhalt anzuzeigen und um Online einzukaufen. Kostenloser Versand weltweit. Sichere Zahlung.",
    },
    {
      locale: "es",
      messages:
        "Elige un país para mostrar el contenido adaptado a tu ubicación geográfica y comprar online. Envío gratuito en tu país. Pago seguro.",
    },
  ]
  const frSpecificLinks = ["les-maux-de-dos"]
  const {
    store: defaultStore,
    locale: countryLocale,
    country,
    handleStore,
  } = countryContext

  const redirectStore = event => {
    let userStore = defaultStore || "eur"
    let userLanguage = countryLocale || "fr"
    let frSpecific = false
    let path = window.location.pathname.split("/")
    let newUrl = "/" + userStore + "/" + userLanguage
    let regexLang = new RegExp(
      "(?:^|W)fr(?:$|W)|(?:^|W)en(?:$|W)|(?:^|W)de(?:$|W)|(?:^|W)es(?:$|W)"
    )
    let regexStore = new RegExp(
      "(?:^|W)eur(?:$|W)|(?:^|W)usd(?:$|W)|(?:^|W)gbp(?:$|W)|(?:^|W)cad(?:$|W)"
    )
    path = path.filter(item => !regexLang.test(item))
    path = path.filter(item => !regexStore.test(item))
    frSpecificLinks.forEach(item => {
      if (
        isBrowser &&
        location.pathname.includes(item) &&
        userLanguage !== "fr"
      ) {
        frSpecific = true
      }
    })
    path.forEach(item => {
      if (item && !frSpecific) newUrl += "/" + item
    })
    localStorage.setItem("favCurr", userStore || "eur")
    if (isBrowser) {
      localStorage.setItem("UserDefaltCountry", country)
      let origin = window.location.origin
      location.assign(origin + newUrl)
    }
    hideBanner()
  }

  const countryBannerText = locale => {
    let countryText = bannerText.filter(item => item.locale === locale)[0]
      .messages
    return countryText
  }
  return (
    <CountryDetectionPopinWrapper className="country_detection_popup">
      <Container>
        <Text>{countryBannerText(countryLocale)}</Text>
        <CountryDetectionSelector
          country={country}
          handleStore={handleStore}
          redirectStore={redirectStore}
        ></CountryDetectionSelector>
        <CloseButton onClick={closeBanner}>×</CloseButton>
      </Container>
    </CountryDetectionPopinWrapper>
  )
}

export default withCountryContext(injectIntl(CountryDetectionPopin))
