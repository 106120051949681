import addToMailchimp from "gatsby-plugin-mailchimp"
import { isBrowser } from "../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

export const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export const NewsletterSubscribe = async ({
  locale,
  subscribeEmail,
  subscribeName,
  assignAbTestGroup,
  formSource,
}) => {
  return new Promise((resolve, reject) => {
    var testGroupArr = ["A", "B"]
    var testGroupVal =
      testGroupArr[Math.floor(Math.random() * testGroupArr.length)]

    if (!subscribeEmail) {
      reject({
        success: null,
        error: "no_email_provided",
        message: "",
      })
      return
    }

    if (!emailRegex.test(subscribeEmail)) {
      reject({
        success: null,
        error: "invalid_email",
        message: "",
      })
      return
    }
    if (!formSource) {
      formSource = "Custom Footer Form"
    }

    const mailchimpSubscription = {
      LANG: locale,
      language: locale,
      Language: locale,
      locale: locale,
    }
    if (assignAbTestGroup) {
      mailchimpSubscription.TEST_GROUP = testGroupVal
    }
    if (subscribeName) {
      mailchimpSubscription.FNAME = subscribeName
    }
    if (isBrowser) {
      var settings = {
        async: true,
        crossDomain: true,
        url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "cache-control": "no-cache",
        },
        data: {
          g:
            process && process.env
              ? process.env.GATSBY_KLAVIYO_LIST_ID
              : "YuDb7j",
          $fields: "$source",
          email: subscribeEmail,
          $source: formSource,
        },
      }

      $.ajax(settings).done(function(response) {
        // console.log(response)
        if (response.success) {
          resolve({
            success: "succesfully_subscribed",
            error: null,
          })
          return
        } else {
          if (response.error.indexOf(subscribeEmail) == 0) {
            resolve({
              success: "already_subscribed",
              error: null,
            })
            return
          } else {
            reject({
              success: null,
              error: "invalid_email",
            })
            return
          }
        }
      })
    }

    // addToMailchimp(subscribeEmail, mailchimpSubscription)
    //   .then(response => {
    //     if (response.result == "success") {
    //       resolve({
    //         success: "succesfully_subscribed",
    //         error: null,
    //       })
    //       return
    //     } else {
    //       if (response.msg.indexOf(subscribeEmail) == 0) {
    //         resolve({
    //           success: "already_subscribed",
    //           error: null,
    //         })
    //         return
    //       } else {
    //         reject({
    //           success: null,
    //           error: "invalid_email",
    //         })
    //         return
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log("Error" + err)
    //     reject({
    //       success: null,
    //       error: "internal_error",
    //     })
    //     return
    //   })
  })
}

export const NewsletterSubscribeWithSMS = async ({
  subscribeEmail,
  subscribePhone,
  formSource,
}) => {
  return new Promise((resolve, reject) => {
    if (!subscribeEmail) {
      reject({
        success: null,
        error: "no_email_provided",
        message: "",
      })
      return
    }

    if (!emailRegex.test(subscribeEmail)) {
      reject({
        success: null,
        error: "invalid_email",
        message: "",
      })
      return
    }
    if (!formSource) {
      formSource = "Newsletter Subscription Popup"
    }

    if (subscribePhone) {
      subscribePhone = subscribePhone.replace(/\s/g, "")
    }

    if (isBrowser) {
      var settings = {
        async: true,
        crossDomain: true,
        url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "cache-control": "no-cache",
        },
        data: {
          g:
            process && process.env
              ? process.env.GATSBY_KLAVIYO_LIST_ID
              : "YuDb7j",
          email: subscribeEmail,
          $phone_number: subscribePhone,
          sms_consent: true,
          $fields: "sms_consent",
          $consent: "['sms']",
          $source: formSource,
        },
      }

      $.ajax(settings).done(function(response) {
        // console.log(response)
        if (response.success) {
          resolve({
            success: "succesfully_subscribed",
            error: null,
          })
          return
        } else {
          if (response.error.indexOf(subscribeEmail) == 0) {
            resolve({
              success: "already_subscribed",
              error: null,
            })
            return
          } else {
            reject({
              success: null,
              error: "invalid_email",
            })
            return
          }
        }
      })
    }
  })
}
