export const CountryDropOptions = () => {
  return [
    {
      store: "eur",
      locale: "fr",
      country_code: "DZ",
      title: "Algérie",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "AU",
      title: "Australia",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "BE",
      title: "Belgique",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "BJ",
      title: "Benin",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "BF",
      title: "Burkina Faso",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "CM",
      title: "Cameroun",
    },
    {
      store: "cad",
      locale: "en",
      country_code: "CA",
      title: "Canada",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "CD",
      title: "Congo",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "CI",
      title: "Côte d'Ivoire",
    },
    {
      store: "eur",
      locale: "es",
      country_code: "ES",
      title: "España",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "FR",
      title: "France",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "GF",
      title: "French Guiana",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "GA",
      title: "Gabon",
    },
    {
      store: "eur",
      locale: "de",
      country_code: "DE",
      title: "Germany",
    },
    {
      store: "eur",
      locale: "en",
      country_code: "GR",
      title: "Greece",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "GP",
      title: "Guadeloupe",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "GN",
      title: "Guinea",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "HT",
      title: "Haiti",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "HK",
      title: "Hong Kong",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "IN",
      title: "India",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "IE",
      title: "Ireland",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "IL",
      title: "Israel",
    },
    {
      store: "eur",
      locale: "en",
      country_code: "IT",
      title: "Italy",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "JP",
      title: "Japan",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "KR",
      title: "Korea",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "MU",
      title: "L'île Maurice",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "LB",
      title: "Lebanon",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "LU",
      title: "Luxembourg",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "MG",
      title: "Madagascar",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "ML",
      title: "Mali",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "MA",
      title: "Maroc",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "MF",
      title: "Martinique",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "YT",
      title: "Mayotte",
    },
    {
      store: "eur",
      locale: "en",
      country_code: "NL",
      title: "Netherlands",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "NC",
      title: "Nouvelle-Calédonie",
    },
    {
      store: "eur",
      locale: "de",
      country_code: "AT",
      title: "Österreich",
    },
    {
      store: "eur",
      locale: "en",
      country_code: "PL",
      title: "Poland",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "PF",
      title: "Polynésie Française",
    },
    {
      store: "eur",
      locale: "en",
      country_code: "PT",
      title: "Portugal",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "RE",
      title: "Réunion",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "RU",
      title: "Russia",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "SN",
      title: "Senegal",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "CH",
      title: "Suisse",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "TH",
      title: "Thailand",
    },
    {
      store: "eur",
      locale: "fr",
      country_code: "TN",
      title: "Tunisie",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "TR",
      title: "Turkey",
    },
    {
      store: "eur",
      locale: "en",
      country_code: "UA",
      title: "Ukraine",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "AE",
      title: "United Arab Emirates",
    },
    {
      store: "gbp",
      locale: "en",
      country_code: "GB",
      title: "United Kingdom",
    },
    {
      store: "usd",
      locale: "en",
      country_code: "US",
      title: "United States",
    },
  ]
}
