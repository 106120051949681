import React from "react"
import Client from "shopify-buy"

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_URL || "checkout.percko.com",
  apiVersion: "2023-07",
  storefrontAccessToken:
    process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN ||
    "91873abe829c1a8e45b1e9015663e7e6",
  //   process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
})

export const defaultStoreContext = {
  client,
  isCartOpen: false,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  addVariantToCart: () => {},
  addMultipleProductToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  updateQuantityInCart: () => {},
  addDiscount: () => {},
  removeDiscount: () => {},
  applyGiftCard: () => {},
  removeGiftCard: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)

export const withStoreContext = Component => {
  return props => (
    <StoreContext.Consumer>
      {context => <Component {...props} storeContext={context} />}
    </StoreContext.Consumer>
  )
}

export default StoreContext
