import React from "react"

export const defaultCountryContext = {
  country: "FR",
  store: "eur",
  locale: "fr",
  handleStore: () => {},
}

const CountryContext = React.createContext(defaultCountryContext)

export const withCountryContext = Component => {
  return props => (
    <CountryContext.Consumer>
      {context => <Component {...props} countryContext={context} />}
    </CountryContext.Consumer>
  )
}

export default CountryContext
