import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { FaAngleRight } from "react-icons/fa"

const DropDownOurExpertise = ({ intl, onMouseLeave }) => {
  const [dropVosOpen, setdropVosOpen] = useState(false)
  return (
    <ProductsDropdownEl style={{ paddingRight: 0 }}>
      <DropdownSection>
        <ProductsSection>
          {intl.locale === "fr" && (
            <List>
              <DropDownIcon
                onClick={e => {
                  setdropVosOpen(true)
                }}
              >
                <FaAngleRight
                  style={{ cursor: "pointer" }}
                  color="262626"
                  size={24}
                />
              </DropDownIcon>
              <Link
                onClick={e => {
                  onMouseLeave()
                }}
                to="/les-maux-de-dos/"
              >
                {intl.formatMessage({ id: "mobile_menu_back_pain" })}
              </Link>
            </List>
          )}
          {/* <List>
            <Link
              onClick={onMouseLeave}
              to={`${
                intl.locale === "fr"
                  ? "/dispositif-medical/"
                  : "/scientific-study/"
              }`}
            >
              {intl.formatMessage({ id: "mobile_menu_research" })}
            </Link>
          </List> */}
          {intl.locale === "fr" && (
            <List>
              <Link
                onClick={onMouseLeave}
                to={`${
                  intl.locale === "fr" ? "/notre-histoire/" : "/our-story/"
                }`}
              >
                {intl.formatMessage({ id: "mobile_menu_our_story" })}
              </Link>
            </List>
          )}

          {intl.locale === "fr" && (
            <List onClick={onMouseLeave}>
              <Link
                to={`${
                  intl.locale === "fr" ? "/ils-nous-aiment/" : "/they-love-us/"
                }`}
              >
                {intl.formatMessage({ id: "mobile_menu_they_love_us" })}
              </Link>
            </List>
          )}
          {intl.locale === "en" && (
            <List>
              <Link
                onClick={onMouseLeave}
                to={`${
                  intl.locale === "fr"
                    ? "/les-maux-de-dos/les-pathologies/la-lombalgie/"
                    : "/back-health/conditions/lower-back-pain/"
                }`}
              >
                {intl.formatMessage({
                  id: "menu__back_pain_conditions_lower_back",
                })}
              </Link>
            </List>
          )}
          {intl.locale === "en" && (
            <List>
              <Link
                onClick={onMouseLeave}
                to={`${
                  intl.locale === "fr"
                    ? "/les-maux-de-dos/les-pathologies/la-hernie-discale/"
                    : "/back-health/conditions/slipped-disk/"
                }`}
              >
                {intl.formatMessage({
                  id: "menu__back_pain_conditions_slipped_disk",
                })}
              </Link>
            </List>
          )}
          {intl.locale === "en" && (
            <List>
              <Link
                onClick={onMouseLeave}
                to={`${
                  intl.locale === "fr"
                    ? "/les-maux-de-dos/les-pathologies/la-scoliose/"
                    : "/back-health/conditions/scoliosis/"
                }`}
              >
                {intl.formatMessage({
                  id: "menu__back_pain_conditions_scoliosis",
                })}
              </Link>
            </List>
          )}
          {intl.locale === "en" && (
            <List>
              <Link
                onClick={onMouseLeave}
                to={`${
                  intl.locale === "fr"
                    ? "/les-maux-de-dos/les-pathologies/la-sciatique/"
                    : "/back-health/conditions/sciatica/"
                }`}
              >
                {intl.formatMessage({
                  id: "menu__back_pain_conditions_sciatica",
                })}
              </Link>
            </List>
          )}
        </ProductsSection>
        <DropDownList dropOpen={dropVosOpen}>
          <DropDownListBack
            dropOpen={dropVosOpen}
            onClick={() => setdropVosOpen(!dropVosOpen)}
          >
            {"< Retour"}
          </DropDownListBack>
          {intl.locale === "fr" && (
            <DropProductsSection>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Vos maux de dos au quotidien
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={onMouseLeave}
                  to={
                    intl.locale === "fr"
                      ? "/les-maux-de-dos/les-maux-de-dos-au-quotidien/ifop-les-salaries-face-aux-tms/"
                      : "/back-health/awareness/ifop-employees-and-msds/"
                  }
                >
                  {intl.formatMessage({
                    id: "menu__back_pain_conditions_tms",
                  })}
                </Link>
              </li> */}
              <li>
                <Link
                  onClick={onMouseLeave}
                  to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/la-sedentarite/"
                >
                  La sédentarité
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/sport-et-mal-de-dos/"
                >
                  Sport et mal de dos
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/le-mal-de-dos-en-voiture/"
                >
                  Le mal de dos en voiture
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/literie-et-mal-de-dos/"
                >
                  Literie et mal de dos
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/le-mal-de-dos-au-bureau/"
                >
                  Le mal de dos au bureau
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/stress-et-mal-de-dos/"
                >
                  Stress et mal de dos
                </Link>
              </li>
            </DropProductsSection>
          )}
          {intl.locale === "fr" && (
            <DropProductsSection>
              {intl.locale === "fr" && (
                <li>
                  <Link
                    onClick={onMouseLeave}
                    to={`${
                      intl.locale === "fr"
                        ? "/les-maux-de-dos/les-pathologies/"
                        : "/back-health/conditions/"
                    }`}
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    {intl.formatMessage({ id: "menu__back_pain_conditions" })}
                  </Link>
                </li>
              )}
              <li>
                <Link
                  onClick={onMouseLeave}
                  to={`${
                    intl.locale === "fr"
                      ? "/les-maux-de-dos/les-pathologies/la-lombalgie/"
                      : "/back-health/conditions/lower-back-pain/"
                  }`}
                >
                  {intl.formatMessage({
                    id: "menu__back_pain_conditions_lower_back",
                  })}
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to={`${
                    intl.locale === "fr"
                      ? "/les-maux-de-dos/les-pathologies/la-hernie-discale/"
                      : "/back-health/conditions/slipped-disk/"
                  }`}
                >
                  {intl.formatMessage({
                    id: "menu__back_pain_conditions_slipped_disk",
                  })}
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to={`${
                    intl.locale === "fr"
                      ? "/les-maux-de-dos/les-pathologies/la-scoliose/"
                      : "/back-health/conditions/scoliosis/"
                  }`}
                >
                  {intl.formatMessage({
                    id: "menu__back_pain_conditions_scoliosis",
                  })}
                </Link>
              </li>
              <li>
                <Link
                  onClick={onMouseLeave}
                  to={`${
                    intl.locale === "fr"
                      ? "/les-maux-de-dos/les-pathologies/la-sciatique/"
                      : "/back-health/conditions/sciatica/"
                  }`}
                >
                  {intl.formatMessage({
                    id: "menu__back_pain_conditions_sciatica",
                  })}
                </Link>
              </li>
              {intl.locale === "fr" && (
                <li>
                  <Link
                    onClick={onMouseLeave}
                    to="/les-maux-de-dos/les-pathologies/la-cruralgie/"
                  >
                    La cruralgie
                  </Link>
                </li>
              )}
              {intl.locale === "fr" && (
                <li>
                  <Link
                    onClick={onMouseLeave}
                    to="/les-maux-de-dos/les-pathologies/la-discopathie/"
                  >
                    La discopathie dégénérative
                  </Link>
                </li>
              )}
            </DropProductsSection>
          )}
        </DropDownList>
      </DropdownSection>
    </ProductsDropdownEl>
  )
}

const ProductsDropdownEl = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`

const ProductsSection = styled.ul`
  margin: 0px;

  li {
    display: flex;
    cursor: pointer;
    margin: 0;
  }

  a {
    color: rgba(102, 102, 102, 0.83);
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding-left: 70px;

    :hover {
      color: rgba(102, 102, 102, 0.83);
    }
  }
`

const List = styled.li`
  display: flex;
  cursor: pointer;
  margin: 0;
  position: relative;

  a {
    color: rgba(102, 102, 102, 0.83);
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding: 10px 20px 10px 70px;

    :hover {
      color: rgba(102, 102, 102, 0.83);
    }
  }
`

const DropdownSection = styled.div`
  position: relative;
  width: 100%;
`

const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  background: #fff;
  width: ${({ dropOpen }) => (dropOpen ? "100%" : "0px")};
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0px;
  z-index: 99;
  -ms-transform: ${({ dropOpen }) =>
    dropOpen ? "translateX(0px)" : "translateX(-260px)"};
  transform: ${({ dropOpen }) =>
    dropOpen ? "translateX(0px)" : "translateX(-260px)"};
  transition: all linear 0.3s;

  a {
    color: rgba(102, 102, 102, 0.83);
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding-left: 50px;

    :hover {
      color: rgba(102, 102, 102, 0.83);
    }
  }
`

const DropDownListBack = styled.div`
  background: #262626;
  color: #fff;
  width: 100%;
  padding: 15px 30px;
  display: ${({ dropOpen }) => (dropOpen ? "block" : "none")};
`

const DropProductsSection = styled.ul`
  margin: 0px;

  li {
    display: flex;
    margin: 0;
    border-bottom: 2px solid #ececec;
    color: #262626;
    font-size: 16px;
    line-height: 24px;
    font-family: Gotham Book;
    position: relative;
    cursor: pointer;
  }

  a {
    color: rgba(102, 102, 102, 0.83);
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding: 21px 20px 20px 50px;

    :hover {
      color: rgba(102, 102, 102, 0.83);
    }
  }
`

const DropDownIcon = styled.div`
  color: #262626;
  cursor: pointer;
  position: absolute;
  right: -4px;
  top: 0px;
  width: 100%;
  height: 40px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export default injectIntl(DropDownOurExpertise)
