export const colorsLyneFit = {
  fr: { "NOIR MICRO-PERFORÉ": "BLACK MICRO-PERFORATED", BLEU: "BLUE" },
  en: {
    "BLACK MICRO-PERFORATED": "BLACK MICRO-PERFORATED",
    BLUE: "BLUE",
  },
  es: { "NEGRO-MICROPERFORADO": "BLACK MICRO-PERFORATED", AZUL: "BLUE" },
  de: {
    "SCHWARZ MIKROPERFORIERT": "BLACK MICRO-PERFORATED",
    BLAU: "BLUE",
  },
}

export const gendersLyneFit = {
  fr: { Homme: "Male", Femme: "Female" },
  en: { Male: "Male", Female: "Female" },
  es: { Hombre: "Male", Mujer: "Female" },
  de: { Männer: "Male", Frauen: "Female" },
}

export const genderReverseLyneFit = {
  fr: { Male: "Homme", Female: "Femme" },
  en: { Male: "Male", Female: "Female" },
  es: { Male: "Hombre", Female: "Mujer" },
  de: { Male: "Männer", Female: "Frauen" },
}

export const colorsReverseLyneFit = {
  fr: { "BLACK MICRO-PERFORATED": "NOIR MICRO-PERFORÉ", BLUE: "BLEU" },
  en: {
    "BLACK MICRO-PERFORATED": "BLACK MICRO-PERFORATED",
    BLUE: "BLUE",
  },
  es: { "BLACK MICRO-PERFORATED": "NEGRO-MICROPERFORADO", BLUE: "AZUL" },
  de: {
    "BLACK MICRO-PERFORATED": "SCHWARZ MIKROPERFORIERT",
    BLUE: "BLAU",
  },
}

export const imagesLyneFit = {
  Male: {
    "BLACK MICRO-PERFORATED":
      "https://static.percko.com/uploads/cd1f1a36-521e-43cb-9cd6-5e1be4379503.jpg",
    BLUE:
      "https://static.percko.com/uploads/a7b60769-7ef5-4d3e-9a6e-d2173af3c582.jpg",
  },
  Female: {
    "BLACK MICRO-PERFORATED":
      "https://static.percko.com/uploads/5aa15db1-ea6e-4e83-9487-bc66209c387b.jpg",
    BLUE:
      "https://static.percko.com/uploads/d8945fad-bba6-4306-91b4-94a6e75726dc.jpg",
  },
}

const default_image =
  "https://static.percko.com/uploads/0c53d7b8-b406-411e-a715-949ebd385155.jpg"

export const imageSliderLyneFit = {
  Default: [
    {
      img:
        "https://static.percko.com/uploads/0c53d7b8-b406-411e-a715-949ebd385155.jpg",
      alt: "",
    },
    {
      img:
        "https://static.percko.com/uploads/633f54a2-7ef2-49df-b9ca-aa8396d9ed0e.jpg",
      alt: "",
    },
    {
      img:
        "https://static.percko.com/uploads/3e848606-f4ce-45f2-9f37-5f009bbcbde9.jpg",
      alt: "",
    },
  ],
  Male: {
    "BLACK MICRO-PERFORATED": [
      {
        img:
          "https://static.percko.com/uploads/87b1c95c-eee5-4460-b2e1-3d8a6c48bb11.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/2d054f07-d49f-48e9-99f7-95965d729fa6.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d9553bfd-726b-4283-9734-3bca59a3a1bf.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e21e3ebf-6b5d-443c-aa96-78e5e1ed2035.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d26f85ad-0048-4f05-b1e6-bd2b213159dd.jpg",
        alt: "",
      },
    ],
    BLUE: [
      {
        img:
          "https://static.percko.com/uploads/64535f6b-c7dd-44a5-a210-d44488565bdf.jpg",
        alt:
          "A front and back shot of a man wearing the blue Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/5dc53717-cfc6-461e-85ae-73a634899a20.jpg",
        alt:
          "A front and side shot of a man wearing the blue Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/788cb102-e7b3-4c2d-bd2c-4a66a05bd5bf.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/a0bfc745-0809-4071-8272-71613b53bf36.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/18ba832e-606a-45d2-a49d-6bdd9533e500.jpg",
        alt:
          "A man running up a hill whilst wearing the blue Lyne FIT back support T-shirt",
      },
    ],
  },
  Female: {
    "BLACK MICRO-PERFORATED": [
      {
        img:
          "https://static.percko.com/uploads/41953790-d7c7-441a-8d6b-3e35a416b53e.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/cbd85765-b21a-4daf-b6b2-6752389857f4.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/44355c46-1001-4108-ae6f-9102b9b4c427.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9c11af68-231c-4bb6-a6e1-44ee1e703263.jpg",
        alt: "",
      },
    ],
    BLUE: [
      {
        img:
          "https://static.percko.com/uploads/9ef7f276-6534-4f4e-ad40-03890e7dab38.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/557251d8-154a-49ae-913d-a773551dd15b.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d07b8fa0-db6a-4517-8c9f-cb85ad246628.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f5b8789a-1522-40ef-908e-7e62efde0d14.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/3432562e-24cf-4b76-b6cc-05f2f60e87c5.jpg",
        alt: "",
      },
    ],
  },
  fr: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/7eae79ba-2e0a-470e-9e81-d86b876da118.jpg",
        alt:
          "Un couple qui court avec un t-shirt de sport qui garde le dos droit et le soulage",
      },
      {
        img:
          "https://static.percko.com/uploads/12779235-4e10-411e-978f-878958909d5b.jpg",
        alt:
          "Un couple s'étirent avant une séance de sport et adoptent de bonnes postures grâce à un t-shirt postural",
      },
      {
        img:
          "https://static.percko.com/uploads/1003eec6-4459-4457-8fd3-6bdd015f7e8a.jpg",
        alt:
          "Une image des t-shirts Lyne FIT homme et femme noir de PERCKO vu de face",
      },
      {
        img:
          "https://static.percko.com/uploads/ccc23cb2-a677-4ca3-a01b-18f67f775a5b.jpg",
        alt: "Une image du t-shirt Lyne UP avec ses 2 plus grands avantages",
      },
      {
        img:
          "https://static.percko.com/uploads/554e9495-0014-4c30-981d-78d3d092291c.jpg",
        alt:
          "Un couple de dos qui court avec un t-shirt de sport qui améliore les performances ",
      },
      {
        img:
          "https://static.percko.com/uploads/06287658-5b68-4460-b7f0-b18c05d6e38d.jpg",
        alt:
          "Un couple qui court avec un t-shirt postural bleu adapté à la pratique sportive ",
      },
    ],
    Male: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/ef54ee04-a19d-4e21-8739-380378bed34d.jpg",
          alt:
            "Un homme de face et de dos avec un t-shirt de sport noir qui redresse le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/a234808e-bf75-406e-9514-7bcd3d0647ec.jpg",
          alt:
            "Un homme de dos et de profil avec une serviette portant un t-shirt noir pour réaligner la colonne vertébrale lors du sport",
        },
        {
          img:
            "https://static.percko.com/uploads/81d44b35-5156-40ec-9263-736647aa7f5a.jpg",
          alt: "Une image du t-shirt Lyne FIT homme noir de PERCKO vu de face",
        },
        {
          img:
            "https://static.percko.com/uploads/1fc4cb0a-6d89-41e4-b683-1030944b00df.jpg",
          alt:
            "Une image du t-shirt Lyne FIT homme noir avec ses 2 plus grands avantages",
        },
        {
          img:
            "https://static.percko.com/uploads/4a842b4e-dc15-4a2f-afc7-48baed1fc929.jpg",
          alt:
            "Un homme de dos qui s'étire en portant un t-shirt postural de sport PERCKO noir ",
        },
        {
          img:
            "https://static.percko.com/uploads/3aa9f387-979d-48fd-808b-5de22235ffda.jpg",
          alt: "",
        },
        // {
        //   img:
        //     "https://static.percko.com/uploads/540a4090-3618-45ad-81ff-aa7559c0c10e.jpg",
        //   alt: "",
        // },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/64535f6b-c7dd-44a5-a210-d44488565bdf.jpg",
          alt:
            "Un homme de face et de dos avec un t-shirt de sport bleu qui redresse le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/5dc53717-cfc6-461e-85ae-73a634899a20.jpg",
          alt:
            "Un homme de dos et de profil avec une serviette portant un t-shirt bleu pour réaligner la colonne vertébrale lors du sport",
        },
        {
          img:
            "https://static.percko.com/uploads/4fcd3ec2-92c4-4b14-a3a8-e689987b22d1.jpg",
          alt: "Une image du t-shirt Lyne FIT homme bleu de PERCKO vu de face",
        },
        {
          img:
            "https://static.percko.com/uploads/888b887c-1284-4545-a75b-ce2b78d74eee.jpg",
          alt:
            "Une image du t-shirt Lyne FIT homme bleu avec ses 2 plus grands avantages",
        },
        {
          img:
            "https://static.percko.com/uploads/db4e8d36-9416-40e2-8af6-d953e34c6bab.jpg",
          alt:
            "Un homme qui étire sa jambe de dos dans une ville avec un t-shirt postural bleu pour améliorer ses performances",
        },
        {
          img:
            "https://static.percko.com/uploads/18ba832e-606a-45d2-a49d-6bdd9533e500.jpg",
          alt:
            "Un homme qui court dans une montée à l'aide de son t-shirt postural bleu qui le tient droit",
        },
      ],
    },
    Female: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/71530694-b5fe-4f8b-adbf-fe445ccc9f68.jpg",
          alt:
            "Une femme de face et de dos avec un t-shirt de sport noir qui redresse le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/62d46257-20dd-4ea6-90dd-a05c49fdc745.jpg",
          alt:
            "Une femme de dos et de profil portant un t-shirt noir pour réaligner la colonne vertébrale lors du sport",
        },
        {
          img:
            "https://static.percko.com/uploads/e86ff7a9-b300-47e1-a4b9-4af00580407f.jpg",
          alt: "Une image du t-shirt Lyne FIT femme noir de PERCKO vu de face",
        },
        {
          img:
            "https://static.percko.com/uploads/5921d596-ccc1-4c45-a342-cb5983f5ec0b.jpg",
          alt:
            "Une image du t-shirt Lyne FIT femme noir avec ses 2 plus grands avantages",
        },
        {
          img:
            "https://static.percko.com/uploads/597e163c-ca13-4b30-af2c-6126921f7a96.jpg",
          alt:
            "Une femme de trois quart avec un t-shirt correcteur de posture noir sur un fond bleu",
        },
        {
          img:
            "https://static.percko.com/uploads/0c3bba83-33f4-426c-9542-0b440cf18b26.jpg",
          alt:
            "Une femme de face qui s'échauffe avant sa séance de sport avec le t-shirt postural Lyne FIT de PERCKO",
        },
        // {
        //   img:
        //     "https://static.percko.com/uploads/5ec9c054-6f3e-4949-8136-92567a8a83e7.jpg",
        //   alt:
        //     "Une femme de face qui s'échauffe avant sa séance de sport avec le t-shirt postural Lyne FIT de PERCKO",
        // },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/060ad358-9aa1-466c-b3ed-00e7cecf8431.jpg",
          alt:
            "Une femme de face et de dos avec un t-shirt de sport bleu qui redresse le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/2cfabc6d-866e-40dd-bc35-b25fcc3a9f4a.jpg",
          alt:
            "Une femme de dos et de profil portant un t-shirt bleu pour réaligner la colonne vertébrale lors du sport",
        },
        {
          img:
            "https://static.percko.com/uploads/cb27044b-833f-40b2-9410-be327ca54fc2.jpg",
          alt: "Une image du t-shirt Lyne FIT femme bleu de PERCKO vu de face",
        },
        {
          img:
            "https://static.percko.com/uploads/f514e372-0e94-4216-9bd5-496478f8c487.jpg",
          alt:
            "Une image du t-shirt Lyne FIT femme bleu avec ses 2 plus grands avantages",
        },
        {
          img:
            "https://static.percko.com/uploads/6ee170dc-6114-42e5-a70c-f15566f53a99.jpg",
          alt:
            "Une femme qui court de dos dans une ville avec un t-shirt postural bleu et l'action des élastiques dans le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/92522f53-114d-49e6-a474-a64f0d2c3d95.jpg",
          alt:
            "Une femme qui s'étire en bord de seine après une séance sportive avec un t-shirt bleu qui la tient droite",
        },
      ],
    },
  },
  en: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/7eae79ba-2e0a-470e-9e81-d86b876da118.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/12779235-4e10-411e-978f-878958909d5b.jpg",
        alt: "A couple stretching wearing the blue Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/1003eec6-4459-4457-8fd3-6bdd015f7e8a.jpg",
        alt:
          "A product shot of the women's and men's black Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/455fb67e-0ea7-44b8-b98e-a18dc3ad1de9.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/554e9495-0014-4c30-981d-78d3d092291c.jpg",
        alt:
          "A couple running with their backs to the camera wearing the black Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/06287658-5b68-4460-b7f0-b18c05d6e38d.jpg",
        alt:
          "A couple running down a rocky hill wearing blue Lyne FIT back support T-shirts",
      },
    ],
    Male: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/ef54ee04-a19d-4e21-8739-380378bed34d.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/a234808e-bf75-406e-9514-7bcd3d0647ec.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/81d44b35-5156-40ec-9263-736647aa7f5a.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/67616c17-010f-40ac-8e4e-2658190c9826.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/4a842b4e-dc15-4a2f-afc7-48baed1fc929.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/3aa9f387-979d-48fd-808b-5de22235ffda.jpg",
          alt: "",
        },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/64535f6b-c7dd-44a5-a210-d44488565bdf.jpg",
          alt:
            "A front and back shot of a man wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/5dc53717-cfc6-461e-85ae-73a634899a20.jpg",
          alt:
            "A front and side shot of a man wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/4fcd3ec2-92c4-4b14-a3a8-e689987b22d1.jpg",
          alt: "A shot of the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/c60cdd7b-9384-445d-b8bb-1fd4ca130bc6.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/db4e8d36-9416-40e2-8af6-d953e34c6bab.jpg",
          alt:
            "A man stretching whilst wearing the blue Lyne FIt sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/18ba832e-606a-45d2-a49d-6bdd9533e500.jpg",
          alt:
            "A man running up a hill whilst wearing the blue Lyne FIT back support T-shirt",
        },
      ],
    },
    Female: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/71530694-b5fe-4f8b-adbf-fe445ccc9f68.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62d46257-20dd-4ea6-90dd-a05c49fdc745.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e86ff7a9-b300-47e1-a4b9-4af00580407f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/358f01e4-072a-4cd8-a1ba-f0bc1e26be22.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/597e163c-ca13-4b30-af2c-6126921f7a96.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0c3bba83-33f4-426c-9542-0b440cf18b26.jpg",
          alt: "",
        },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/060ad358-9aa1-466c-b3ed-00e7cecf8431.jpg",
          alt:
            "A front and back shot of a woman wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/2cfabc6d-866e-40dd-bc35-b25fcc3a9f4a.jpg",
          alt:
            "A front and side shot of a woman wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/cb27044b-833f-40b2-9410-be327ca54fc2.jpg",
          alt: "A product shot of the womens' blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/c638ae2c-d1de-454f-a33c-a6edb02f4538.jpg",
          alt:
            "A zoom on both the fabric and the clips of the women's blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/6ee170dc-6114-42e5-a70c-f15566f53a99.jpg",
          alt:
            "A women running through the streets wearing the PERCKO back support sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/92522f53-114d-49e6-a474-a64f0d2c3d95.jpg",
          alt:
            "A woman stretching by the river wearing the blue Lyne FIT back support for sports",
        },
      ],
    },
  },
  de: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/7eae79ba-2e0a-470e-9e81-d86b876da118.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/12779235-4e10-411e-978f-878958909d5b.jpg",
        alt: "A couple stretching wearing the blue Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/1003eec6-4459-4457-8fd3-6bdd015f7e8a.jpg",
        alt:
          "A product shot of the women's and men's black Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/554e9495-0014-4c30-981d-78d3d092291c.jpg",
        alt:
          "A couple running with their backs to the camera wearing the black Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/06287658-5b68-4460-b7f0-b18c05d6e38d.jpg",
        alt:
          "A couple running down a rocky hill wearing blue Lyne FIT back support T-shirts",
      },
    ],
    Male: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/ef54ee04-a19d-4e21-8739-380378bed34d.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/a234808e-bf75-406e-9514-7bcd3d0647ec.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/81d44b35-5156-40ec-9263-736647aa7f5a.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/4a842b4e-dc15-4a2f-afc7-48baed1fc929.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/3aa9f387-979d-48fd-808b-5de22235ffda.jpg",
          alt: "",
        },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/64535f6b-c7dd-44a5-a210-d44488565bdf.jpg",
          alt:
            "A front and back shot of a man wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/5dc53717-cfc6-461e-85ae-73a634899a20.jpg",
          alt:
            "A front and side shot of a man wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/4fcd3ec2-92c4-4b14-a3a8-e689987b22d1.jpg",
          alt: "A shot of the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/db4e8d36-9416-40e2-8af6-d953e34c6bab.jpg",
          alt:
            "A man stretching whilst wearing the blue Lyne FIt sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/18ba832e-606a-45d2-a49d-6bdd9533e500.jpg",
          alt:
            "A man running up a hill whilst wearing the blue Lyne FIT back support T-shirt",
        },
      ],
    },
    Female: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/71530694-b5fe-4f8b-adbf-fe445ccc9f68.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62d46257-20dd-4ea6-90dd-a05c49fdc745.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e86ff7a9-b300-47e1-a4b9-4af00580407f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/597e163c-ca13-4b30-af2c-6126921f7a96.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0c3bba83-33f4-426c-9542-0b440cf18b26.jpg",
          alt: "",
        },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/060ad358-9aa1-466c-b3ed-00e7cecf8431.jpg",
          alt:
            "A front and back shot of a woman wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/2cfabc6d-866e-40dd-bc35-b25fcc3a9f4a.jpg",
          alt:
            "A front and side shot of a woman wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/cb27044b-833f-40b2-9410-be327ca54fc2.jpg",
          alt: "A product shot of the womens' blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/6ee170dc-6114-42e5-a70c-f15566f53a99.jpg",
          alt:
            "A women running through the streets wearing the PERCKO back support sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/92522f53-114d-49e6-a474-a64f0d2c3d95.jpg",
          alt:
            "A woman stretching by the river wearing the blue Lyne FIT back support for sports",
        },
      ],
    },
  },
  es: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/7eae79ba-2e0a-470e-9e81-d86b876da118.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/12779235-4e10-411e-978f-878958909d5b.jpg",
        alt: "A couple stretching wearing the blue Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/1003eec6-4459-4457-8fd3-6bdd015f7e8a.jpg",
        alt:
          "A product shot of the women's and men's black Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/554e9495-0014-4c30-981d-78d3d092291c.jpg",
        alt:
          "A couple running with their backs to the camera wearing the black Lyne FIT sports T-shirt",
      },
      {
        img:
          "https://static.percko.com/uploads/06287658-5b68-4460-b7f0-b18c05d6e38d.jpg",
        alt:
          "A couple running down a rocky hill wearing blue Lyne FIT back support T-shirts",
      },
    ],
    Male: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/ef54ee04-a19d-4e21-8739-380378bed34d.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/a234808e-bf75-406e-9514-7bcd3d0647ec.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/81d44b35-5156-40ec-9263-736647aa7f5a.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/4a842b4e-dc15-4a2f-afc7-48baed1fc929.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/3aa9f387-979d-48fd-808b-5de22235ffda.jpg",
          alt: "",
        },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/64535f6b-c7dd-44a5-a210-d44488565bdf.jpg",
          alt:
            "A front and back shot of a man wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/5dc53717-cfc6-461e-85ae-73a634899a20.jpg",
          alt:
            "A front and side shot of a man wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/4fcd3ec2-92c4-4b14-a3a8-e689987b22d1.jpg",
          alt: "A shot of the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/db4e8d36-9416-40e2-8af6-d953e34c6bab.jpg",
          alt:
            "A man stretching whilst wearing the blue Lyne FIt sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/18ba832e-606a-45d2-a49d-6bdd9533e500.jpg",
          alt:
            "A man running up a hill whilst wearing the blue Lyne FIT back support T-shirt",
        },
      ],
    },
    Female: {
      "BLACK MICRO-PERFORATED": [
        {
          img:
            "https://static.percko.com/uploads/71530694-b5fe-4f8b-adbf-fe445ccc9f68.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62d46257-20dd-4ea6-90dd-a05c49fdc745.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e86ff7a9-b300-47e1-a4b9-4af00580407f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/597e163c-ca13-4b30-af2c-6126921f7a96.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0c3bba83-33f4-426c-9542-0b440cf18b26.jpg",
          alt: "",
        },
      ],
      BLUE: [
        {
          img:
            "https://static.percko.com/uploads/060ad358-9aa1-466c-b3ed-00e7cecf8431.jpg",
          alt:
            "A front and back shot of a woman wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/2cfabc6d-866e-40dd-bc35-b25fcc3a9f4a.jpg",
          alt:
            "A front and side shot of a woman wearing the blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/cb27044b-833f-40b2-9410-be327ca54fc2.jpg",
          alt: "A product shot of the womens' blue Lyne FIT sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/6ee170dc-6114-42e5-a70c-f15566f53a99.jpg",
          alt:
            "A women running through the streets wearing the PERCKO back support sports T-shirt",
        },
        {
          img:
            "https://static.percko.com/uploads/92522f53-114d-49e6-a474-a64f0d2c3d95.jpg",
          alt:
            "A woman stretching by the river wearing the blue Lyne FIT back support for sports",
        },
      ],
    },
  },
}

export const LyneFitNewImageSliders = {
  Male: {
    "BLACK MICRO-PERFORATED": [
      {
        img:
          "https://static.percko.com/uploads/33aa1f5e-e7bb-48e4-9ded-37c592bd590e.jpg",
        alt: "",
        imageText: "De S à XXL",
      },
      {
        img:
          "https://static.percko.com/uploads/96316faa-f5b2-40cc-b8cb-8417cdc013b2.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f448813e-b5c6-4c68-9fc1-6c46ae1be291.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/1f00687f-789f-426d-85ca-18ba340e8c1a.jpg",
        alt: "",
        imageText: "Attaches réglables sur 3 niveaux",
      },
      {
        img:
          "https://static.percko.com/uploads/8438a2d9-7b30-4e8f-bd3a-032c352674d7.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/2ba76a00-ce58-4b09-a37b-2705c0555156.jpg",
        alt: "",
        imageText: "",
      },
    ],
  },
  Female: {
    "BLACK MICRO-PERFORATED": [
      {
        img:
          "https://static.percko.com/uploads/4a5a0d17-cec2-4d36-b748-c0a9f9b47488.jpg",
        alt: "",
        imageText: "De XS à XL",
      },
      {
        img:
          "https://static.percko.com/uploads/ba0aac72-960c-4910-acc7-72205a32baab.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/991b6fc7-840c-4243-99d9-00b1358f178e.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/7ae32ddd-d09a-42c8-bed0-676469430147.jpg",
        alt: "",
        imageText: "Attaches réglables sur 3 niveaux",
      },
      {
        img:
          "https://static.percko.com/uploads/74af6c77-7b7d-4203-a7b0-2694228d2a5f.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/ad931741-bd26-4108-a690-edfa7d9c2d63.jpg",
        alt: "",
        imageText: "",
      },
    ],
  },
}

export const LyneFitImageSliders = {
  Male: {
    Noir: [
      {
        img:
          "https://static.percko.com/uploads/3f6957fb-51a5-4e0c-8169-bf459d684d39.jpg",
        alt: "",
        imageText: "Mannequin de 1,85m, porte la taille XL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f1e398e9-92b3-40fa-8c1b-c8ee760659f1.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6a5a68da-9419-4e26-8aff-2d81872336f8.jpg",
        alt: "",
        imageText: "De S à XXL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0bc70df4-3c85-4049-8b7c-13314cd311d8.jpg",
        alt: "",
        imageText: "Attaches réglables sur 3 niveaux",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_sport_homme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/8a909166-ab7f-4b55-b17a-5bbfa5f93598.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/1f0d48a0-b087-4a49-97ba-d8ac1f8a74c7.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
    ],
    "Noir MP": [
      {
        img:
          "https://static.percko.com/uploads/3f6957fb-51a5-4e0c-8169-bf459d684d39.jpg",
        alt: "",
        imageText: "Mannequin de 1,85m, porte la taille XL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f1e398e9-92b3-40fa-8c1b-c8ee760659f1.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6a5a68da-9419-4e26-8aff-2d81872336f8.jpg",
        alt: "",
        imageText: "De S à XXL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0bc70df4-3c85-4049-8b7c-13314cd311d8.jpg",
        alt: "",
        imageText: "Attaches réglables sur 3 niveaux",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_sport_homme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/8a909166-ab7f-4b55-b17a-5bbfa5f93598.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/1f0d48a0-b087-4a49-97ba-d8ac1f8a74c7.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
    ],
  },
  Female: {
    Noir: [
      {
        img:
          "https://static.percko.com/uploads/71521a9e-7c90-4df2-8dc4-5d61b39364bb.jpg",
        alt: "",
        imageText: "Mannequin de 1,72m, porte la taille M",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f1e398e9-92b3-40fa-8c1b-c8ee760659f1.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/c77f4e33-0365-4bae-aacb-299bb0582513.jpg",
        alt: "",
        imageText: "De XS à XL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/386030ee-bf54-449d-9e42-bc992020d0b7.jpg",
        alt: "",
        imageText: "Attaches réglables sur 3 niveaux",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_sport_femme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/ab4787a8-745f-4922-8613-3d1152cf34c1.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/19b60363-28bb-4fe3-aab4-7fb19350b1b6.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
    ],
    "Noir MP": [
      {
        img:
          "https://static.percko.com/uploads/71521a9e-7c90-4df2-8dc4-5d61b39364bb.jpg",
        alt: "",
        imageText: "Mannequin de 1,72m, porte la taille M",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f1e398e9-92b3-40fa-8c1b-c8ee760659f1.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/c77f4e33-0365-4bae-aacb-299bb0582513.jpg",
        alt: "",
        imageText: "De XS à XL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/386030ee-bf54-449d-9e42-bc992020d0b7.jpg",
        alt: "",
        imageText: "Attaches réglables sur 3 niveaux",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_sport_femme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/ab4787a8-745f-4922-8613-3d1152cf34c1.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/19b60363-28bb-4fe3-aab4-7fb19350b1b6.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
    ],
  },
}

export const CapitalizeFirstLetter = string => {
  return string[0].toUpperCase() + string.slice(1)
}
