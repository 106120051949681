import React, { useState } from "react"
import styled from "@emotion/styled"
import Popover, { ArrowContainer } from "react-tiny-popover"
import { injectIntl } from "gatsby-plugin-intl"
import { dropLangOptions } from "../../../../utils/langDropHelper"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../../context/ApolloContext"
import { handelLangRedirects } from "../../../../utils/langCurrSeclectorHelper"

const languages = dropLangOptions()

const Language = ({ flag, alt, title, locale, ...props }) => {
  const { pathname, search, origin } = useLocation()

  const handleOnClick = () => {
    if (isBrowser) {
      let newLocation = handelLangRedirects({
        locale: locale,
        origin: origin,
        pathname: pathname,
        search: search,
      })
      location = newLocation
    }
  }

  return (
    <a {...props} onClick={() => handleOnClick()}>
      <img src={flag} alt={alt} title={title} />
      <span>{title}</span>
    </a>
  )
}

const LanguageSelectedWrapper = styled(Language)`
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-content: center;
  color: ${props => props.theme.colors.white.grey};
  background: #000;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 2px;
  padding: 8px 16px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #cecece;
  }

  img {
    margin-right: 5px;
    margin-bottom: 0;
    max-width: 18px;
  }

  span {
    width: auto;
  }
`

const LanguageWrapper = styled(LanguageSelectedWrapper)`
  background: #fff;
  color: ${props => props.theme.colors.black.light};
  :hover {
    color: ${props => props.theme.colors.black.light};
    background-color: #eee;
  }
`
const Languages = ({ onMouseLeave }) => (
  <div onMouseLeave={onMouseLeave}>
    {languages.map((lang, index) => (
      <LanguageWrapper key={index.toString()} {...lang} />
    ))}
  </div>
)

const PopoverWrapper = styled(Popover)`
  top: 27px !important;
  color: ${props => props.theme.colors.black.light};
  > :first-of-type {
    background-color: #fff !important;
  }
`

const LanguageSelector = injectIntl(({ intl }) => {
  const { locale } = intl
  const lang = languages.find(lang => lang.locale === locale)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <PopoverWrapper
      disableReposition
      isOpen={isOpen}
      position={["bottom"]}
      //   content={Languages}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={"#fff"}
          arrowSize={7}
          arrowStyle={{ opacity: 1 }}
        >
          <Languages onMouseLeave={() => setIsOpen(false)} />
        </ArrowContainer>
      )}
      onClickOutside={() => setIsOpen(false)}
    >
      <LanguageSelectedWrapper onMouseEnter={() => setIsOpen(true)} {...lang} />
    </PopoverWrapper>
  )
})

export default LanguageSelector
