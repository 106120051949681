export const dropLangOptions = () => {
  return [
    {
      locale: "fr",
      alt: "fr",
      title: "Français",
      flag:
        "https://static.percko.com/uploads/efb3453c-5c09-49a3-8fb7-26030083e449.png",
    },
    {
      locale: "en",
      alt: "en",
      title: "English",
      flag:
        "https://static.percko.com/uploads/7712def5-acdf-4cff-b5a9-85fd97382549.png",
    },
    {
      locale: "de",
      alt: "de",
      title: "Deutsch",
      flag:
        "https://static.percko.com/uploads/1a480f9c-1e51-4453-8a4e-c30e55c85fb7.png",
    },
    {
      locale: "es",
      alt: "es",
      title: "Español",
      flag:
        "https://static.percko.com/uploads/f2f7c004-c63c-4342-b41c-b68a592e66fc.png",
    },
  ]
}

export const dropCurrencyOptions = () => {
  return [
    {
      curr: "eur",
      alt: "euro",
      title: "€ EUR",
      lang: ["fr", "en", "de", "es"],
    },
    {
      curr: "usd",
      alt: "US Dollar",
      title: "$ USD",
      lang: ["en"],
    },
    {
      curr: "gbp",
      alt: "UK Pound",
      title: "£ GBP",
      lang: ["en"],
    },
    {
      curr: "cad",
      alt: "Canadian Dollar",
      title: "$ CAD",
      lang: ["fr", "en"],
    },
  ]
}
