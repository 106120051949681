import React, { useState, useEffect } from "react"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Modal from "react-modal"

Modal.setAppElement(`#___gatsby`)

const customStyles = {
  overlay: {
    background: "rgba(255,255,255,0.5)",
    zIndex: 99999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    border: "none",
  },
}

const LoaderSpin = ({ loading }) => {
  const [IsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    loading ? openModal() : closeModal()
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <Modal isOpen={IsOpen} style={customStyles} contentLabel="Modal">
      <div
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Loader
          type="Oval"
          color="#808080"
          height={30}
          width={30}
          secondaryColor={"#696969"}
        />
      </div>
    </Modal>
  )
}

export default LoaderSpin
