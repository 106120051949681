import { navigate } from "@reach/router"

export const navigateToCart = locale => {
  let urlWithLocale = ""
  if (
    process.env.GATSBY_ACTIVE_ENV === "general" ||
    process.env.GATSBY_ACTIVE_ENV === "eur"
  ) {
    if (locale != "fr") {
      urlWithLocale = "/" + locale
    }
  } else if (process.env.GATSBY_ACTIVE_ENV === "cad") {
    if (locale === "fr") {
      urlWithLocale = "/" + locale
    }
  } else {
    urlWithLocale = ""
  }
  navigate(
    `${
      process.env.NODE_ENV === "development" ||
      !process.env.GATSBY_ACTIVE_ENV ||
      process.env.GATSBY_ACTIVE_ENV === "general"
        ? ""
        : "/" + process.env.GATSBY_ACTIVE_ENV
    }${urlWithLocale}/${locale === "fr" ? "panier/" : "cart/"}`
  )
}

export const getValidPrice = price => {
  price = Number(price).toFixed(2)
  let newPrice = ""
  if (Number(price) == price && price % 1 == 0) {
    newPrice = Number(price).toFixed(0)
  } else if (Number(price) == price && (price * 10) % 1 == 0) {
    newPrice = Number(price).toFixed(2)
  } else {
    newPrice = price
  }
  return newPrice
}

export const getPrice = price => {
  price = Number(price).toFixed(2)
  let newPrice = ""
  if (Number(price) == price && price % 1 == 0) {
    newPrice = Number(price).toFixed(0)
  } else if (Number(price) == price && (price * 10) % 1 == 0) {
    newPrice = Number(price).toFixed(2)
  } else {
    newPrice = price.toString()
  }
  if (process.env && process.env.GATSBY_CURRENCY_TYPE === "eur") {
    newPrice = newPrice.toString().replace(".", ",")
  }
  return newPrice
}

export const stringToPrice = price => {
  if (process.env && process.env.GATSBY_CURRENCY_TYPE === "eur") {
    price = price.toString().replace(",", ".")
  }
  return price
}

export const monthlyPrice = (price, installmentCount = 3) => {
  price = stringToPrice(price)

  let totalPrice = Number(price)
  let monthPrice = getPrice(Number(totalPrice / installmentCount).toFixed(2))
  if (process.env.GATSBY_CURRENCY_TYPE === "eur") {
    monthPrice = monthPrice + process.env.GATSBY_CURRENCY
  } else {
    monthPrice = process.env.GATSBY_CURRENCY + monthPrice
  }
  return monthPrice
}
