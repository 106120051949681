import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { useLocation } from "@reach/router"

const BlackFridayStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 7px 20px;
  font-size: 18px;
  text-align: center;
  background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  position: relative;
  overflow: hidden;

  @media (max-width: 450px) {
    padding: 7px 10px;
  }
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextBoxWrapper = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  text-align: left;
`

const LeftText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  // background: ${props => props.theme.saleColor.bgTagColor};
  // color: ${props => props.theme.saleColor.textColor};
  // border: ${props => "2px solid" + props.theme.saleColor.borderColor};
  // padding: 7px 8px 6px;

  span {
    display: flex;
    margin-left: 5px;

    @media (max-width: 767px) {
      display: block;
      margin-left: 0px;
    }
  }

  @media (max-width: 767px) {
    font-size: 18px;
    display: inline;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    margin-left: -10px;
    // padding: 5px 6px;
  }

  @media (max-width: 350px) {
      font-size: 16px;
  }
`

const BlackFridayLogo = styled.img`
  width: 100%;
  max-width: 150px;
  margin: 0px;
  object-fit: contain;

  @media (max-width: 380px) {
    max-width: 130px;
  }

  @media (max-width: 350px) {
    max-width: 120px;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin-left: ${({ marginLeft }) => (marginLeft ? "10px" : "0px")};
  color: #fff;

  > span:nth-of-type(1) {
    margin: 0px 7px;
    font-size: 24px;
    font-weight: 900;
    padding: 7px 8px 6px;
    background: #fff;
    color: #141211;

    @media (max-width: 767px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
      padding: 5px 6px;
    }

    @media (max-width: 380px) {
      margin: 0px 5px;
      font-size: 16px;
    }
  }

  span > span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  sup {
    margin-right: 4px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    margin-left: ${({ marginLeft }) => (marginLeft ? "7px" : "0px")};
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    margin-left: ${({ marginLeft }) => (marginLeft ? "5px" : "0px")};
    font-size: 13px;
  }
`

const BlackFridayStickyBanner = ({ intl }) => {
  const [activeFlag, setactiveFlag] = useState(true)
  const [shopButtonHide, setShopButtonHide] = useState(false)
  const [isLynePage, setisLynePage] = useState(false)
  const [isOreiller, setisOreiller] = useState(false)
  const [isMatelas, setisMatelas] = useState(false)
  const [isSurmatelas, setisSurmatelas] = useState(false)
  const [isSommierPage, setisSommierPage] = useState(false)
  const [isChaisePage, setisChaisePage] = useState(false)

  const { pathname } = useLocation()

  const checkButtonVisibility = () => {
    let currentPath = ""
    let ButtonHide = false

    let regex = /(lyneup|lynefit|lynehome|matelas|surmatelas|oreiller|sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique|oreillers-ergonomiques|chaise-bureau-ergonomique|shop|panier|cart|dispositif-medical|scientific-study)/
    let lyneRegex = /(lyneup|lynefit|lynehome|wearable-technology|solutions-textiles)/
    let oreillerRegex = /(oreiller|oreillers-ergonomiques)/
    let matelasRegex = /(matelas)/
    let surMatelasRegex = /(surmatelas)/
    let sommierRegex = /(sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique)/
    let chaiseRegex = /(chaise-bureau-ergonomique)/

    currentPath = window.location.pathname

    ButtonHide = regex.test(currentPath)
    setShopButtonHide(ButtonHide)
    setisLynePage(lyneRegex.test(currentPath))
    setisOreiller(oreillerRegex.test(currentPath))
    setisMatelas(matelasRegex.test(currentPath))
    setisSurmatelas(surMatelasRegex.test(currentPath))
    setisSommierPage(sommierRegex.test(currentPath))
    setisChaisePage(chaiseRegex.test(currentPath))
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }
    $(window).resize(() => {
      if (isOnScreen($(".HomeVideoBanner"))) {
        setactiveFlag(false)
      } else {
        setactiveFlag(true)
      }
      window.addEventListener("scroll", function(e) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
      })
    })

    $(document).ready(function() {
      if (isOnScreen($(".HomeVideoBanner"))) {
        setactiveFlag(false)
      } else {
        setactiveFlag(true)
      }
      window.addEventListener("scroll", function(e) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
      })
    })
  }

  useEffect(() => {
    isBrowser && checkButtonVisibility()
    isBrowser && BottomBarActive()
  }, [pathname])
  return (
    activeFlag && (
      <BlackFridayStickyBannerWrapper className="LyneHomeStickyBanner">
        {shopButtonHide ? (
          <Container>
            {isLynePage ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText>
                  <span>-60%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isOreiller ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText>
                  <span>-30%</span>
                  <span>dès 3 oreillers</span>
                </RightText>
              </TextBox>
            ) : isSurmatelas ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText>
                  <span>-20%</span>
                  <span>
                    sur le <span>surmatelas</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isMatelas ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText>
                  <span>-30%</span>
                  <span>
                    sur le pack <span>matelas + oreiller</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isSommierPage ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText>
                  <span>-30%</span>
                  <span>
                    sur le pack <span>sommier + matelas</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isChaisePage ? (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText>
                  <span>-100€</span>
                  <span>
                    sur la chaise <span>ergonomique</span>
                  </span>
                </RightText>
              </TextBox>
            ) : (
              <TextBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText marginLeft="10px">
                  Jusqu’à
                  <span>-60%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            )}
          </Container>
        ) : (
          <Container>
            <TextBoxWrapper>
              <TextBox>
                <LinkBox to="/shop/offres/">
                  <LinkButtonTitle>Je découvre</LinkButtonTitle>
                </LinkBox>
                <LeftText>
                  <BlackFridayLogo src="https://static.percko.com/uploads/ba96bf00-bf22-41ff-a5e0-ad5e3ed5ba58.gif" />
                </LeftText>
                <RightText marginLeft="10px">
                  Jusqu’à
                  <span>-60%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            </TextBoxWrapper>
          </Container>
        )}
      </BlackFridayStickyBannerWrapper>
    )
  )
}

export default injectIntl(BlackFridayStickyBanner)
