export const colors = {
  fr: {
    NOIR: "BLACK",
    NUDE: "NUDE",
    "NOIR MICRO-PERFORÉ": "BLACK MICRO-PERFORATED",
    BLEU: "BLUE",
  },
  en: {
    BLACK: "BLACK",
    NUDE: "NUDE",
    "BLACK MICRO-PERFORATED": "BLACK MICRO-PERFORATED",
    BLUE: "BLUE",
  },
  es: {
    NEGRO: "BLACK",
    NUDE: "NUDE",
    "NEGRO-MICROPERFORADO": "BLACK MICRO-PERFORATED",
    AZUL: "BLUE",
  },
  de: {
    SCHWARZ: "BLACK",
    NUDE: "NUDE",
    "SCHWARZ MIKROPERFORIERT": "BLACK MICRO-PERFORATED",
    BLAU: "BLUE",
  },
}

export const genders = {
  fr: { Homme: "Male", Femme: "Female" },
  en: { Male: "Male", Female: "Female" },
  es: { Hombre: "Male", Mujer: "Female" },
  de: { Männer: "Male", Frauen: "Female" },
}

export const genderReverse = {
  fr: { Male: "Homme", Female: "Femme" },
  en: { Male: "Male", Female: "Female" },
  es: { Male: "Hombre", Female: "Mujer" },
  de: { Male: "Männer", Female: "Frauen" },
}

export const colorsReverse = {
  fr: {
    BLACK: "NOIR",
    NUDE: "NUDE",
    "BLACK MICRO-PERFORATED": "NOIR MICRO-PERFORÉ",
    BLUE: "BLEU",
  },
  en: {
    BLACK: "BLACK",
    NUDE: "NUDE",
    "BLACK MICRO-PERFORATED": "BLACK MICRO-PERFORATED",
    BLUE: "BLUE",
  },
  es: {
    BLACK: "NEGRO",
    NUDE: "NUDE",
    "BLACK MICRO-PERFORATED": "NEGRO-MICROPERFORADO",
    BLUE: "AZUL",
  },
  de: {
    BLACK: "SCHWARZ",
    NUDE: "NUDE",
    "BLACK MICRO-PERFORATED": "SCHWARZ MIKROPERFORIERT",
    BLUE: "BLAU",
  },
}

export const colorsNew = {
  fr: {
    Noir: "Noir",
    Gris: "Gris",
    Sable: "Sable",
    "Rose poudré": "Rose poudré",
    "Noir MP": "Noir MP",
  },
}

export const colorsNewReverse = {
  fr: {
    Noir: "Noir",
    Gris: "Gris",
    Sable: "Sable",
    "Rose poudré": "Rose poudré",
    "Noir MP": "Noir micro-perforé",
  },
}

export const lyneProductType = {
  fr: {
    "Sous-vetement": "lyneup",
    Gilet: "lynehome",
    "T-shirt-sport": "lynefit",
  },
  en: {
    "Sous-vetement": "lyneup",
    Gilet: "lynehome",
    "T-shirt-sport": "lynefit",
  },
  es: {
    "Sous-vetement": "lyneup",
    Gilet: "lynehome",
    "T-shirt-sport": "lynefit",
  },
  de: {
    "Sous-vetement": "lyneup",
    Gilet: "lynehome",
    "T-shirt-sport": "lynefit",
  },
}

export const lyneProductTypeReverse = {
  fr: {
    lyneup: "Sous-vetement",
    lynehome: "Gilet",
    lynefit: "T-shirt-sport",
  },
  en: {
    lyneup: "Sous-vetement",
    lynehome: "Gilet",
    lynefit: "T-shirt-sport",
  },
  es: {
    lyneup: "Sous-vetement",
    lynehome: "Gilet",
    lynefit: "T-shirt-sport",
  },
  de: {
    lyneup: "Sous-vetement",
    lynehome: "Gilet",
    lynefit: "T-shirt-sport",
  },
}

export const lyneNewProductType = {
  fr: {
    Male: {
      "tshirt-discret-homme": "lyneup",
      "gilet-pratique-homme": "lynehome",
      "tshirt-de-sport-homme": "lynefit",
    },
    Female: {
      "tshirt-discret-femme": "lyneup",
      "gilet-pratique-femme": "lynehome",
      "tshirt-de-sport-femme": "lynefit",
    },
  },
}

export const lyneNewProductTypeReverse = {
  fr: {
    Male: {
      lyneup: "tshirt-discret-homme",
      lynehome: "gilet-pratique-homme",
      lynefit: "tshirt-de-sport-homme",
    },
    Female: {
      lyneup: "tshirt-discret-femme",
      lynehome: "gilet-pratique-femme",
      lynefit: "tshirt-de-sport-femme",
    },
  },
}

export const images = {
  Male: {
    BLACK:
      "https://static.percko.com/uploads/391d2eb0-468a-49a1-9d8d-7c98070a58b5.png",
    NUDE:
      "https://static.percko.com/uploads/e08d0acc-a61d-4aa9-a7a5-9603062494e7.png",
  },
  Female: {
    BLACK:
      "https://static.percko.com/uploads/dcb3a8b3-39de-4132-8b86-749c7d82f62e.png",
    NUDE:
      "https://static.percko.com/uploads/87e9156e-5316-4c0c-9c9f-318bfbd989b9.png",
  },
}

export const LyneUp2ImageSliders = {
  fr: {
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/1bce4a2c-f075-4fa9-b092-b8e707423da0.jpg",
          alt: "A man and woman wearing the Lyne UP back support vest",
        },
        {
          img:
            "https://static.percko.com/uploads/f2e65d54-6ca2-48fe-84aa-d82b54fc35b5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d15e7b57-5cbc-490c-92de-c1d14914fb18.jpg",
          alt:
            "Un homme et une femme qui porte chacun un modèle du t-shirt postural",
        },
        {
          img:
            "https://static.percko.com/uploads/f903a4be-77cd-4423-b5b8-c9aae7d73397.jpg",
          alt:
            "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        },
        {
          img:
            "https://static.percko.com/uploads/6e52c8be-39df-4c33-8255-1db267a6cd19.jpg",
          alt:
            "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        },
        {
          img:
            "https://static.percko.com/uploads/4d90c25a-ccd3-472c-9a34-dde0c873ac1b.jpg",
          alt:
            "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/5ee87bfd-a9cf-4536-b7ab-5f80c1024c03.jpg",
          alt:
            "Un homme qui porte un t-shirt nude avec des tenseurs pour soulager le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/a0327519-6743-4555-90fa-eaf00c01970e.jpg",
          alt:
            "Un homme avec un t-shirt postural nude et une chemise par-dessus",
        },
        {
          img:
            "https://static.percko.com/uploads/fa2b9db3-fda3-48ee-9fb6-007aa102d8b5.jpg",
          alt:
            "Un homme avec une chemise qui recouvre un t-shirt nude discret sous les vêtements",
        },
        {
          img:
            "https://static.percko.com/uploads/855676a1-248c-4f30-93ea-9121a2de4883.jpg",
          alt:
            "Un homme assis qui porte un t-shirt nude avec des tenseurs pour soulager son dos",
        },
        {
          img:
            "https://static.percko.com/uploads/e7810e37-2b9a-4ef3-b3bb-3c8fcc896cef.jpg",
          alt: "Une image du t-shirt Lyne UP homme nude de PERCKO vu de face",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/f33460e3-7d7f-47e6-9d7d-c02865313ee1.jpg",
          alt:
            "Une femme qui porte un t-shirt noir avec des tenseurs pour soulager le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/7324df78-db2c-4a7a-b9a6-d346bc8a2cde.jpg",
          alt:
            "Une femme assise de dos qui porte un t-shirt noir avec des tenseurs pour se redresser",
        },
        {
          img:
            "https://static.percko.com/uploads/497ecdef-92c9-4565-b45a-9e3c8ec556d5.jpg",
          alt:
            "Une femme de profil portant un t-shirt postural noir et qui se tient droite",
        },
        {
          img:
            "https://static.percko.com/uploads/2c91cc35-d6fc-45b3-b609-9fbcfa788df2.jpg",
          alt:
            "Une femme de dos avec un t-shirt composé de tenseurs et qui se tient droite",
        },
        {
          img:
            "https://static.percko.com/uploads/a1f69734-4f32-44ce-b5b3-1d0a55e6c099.jpg",
          alt:
            "A before and after photo of a woman correcting her posture with the Lyne UP posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/78a4569b-9f03-4066-b000-ce71f9e3c5d0.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest from semi-profile",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/94ee2b0a-b354-4692-8a2d-d9eb6db7c899.jpg",
          alt:
            "Une femme de dos qui porte un t-shirt nude avec des tenseurs pour redresser son dos",
        },
        {
          img:
            "https://static.percko.com/uploads/8f2cf1ff-a790-4709-a328-0247fcc9dbbc.jpg",
          alt:
            "Une femme assise de dos qui porte un t-shirt nude avec des tenseurs pour se soulager",
        },
        {
          img:
            "https://static.percko.com/uploads/fc3054d6-2ca7-477a-af22-eb6c25f5727c.jpg",
          alt:
            "Une femme de face avec un tshirt postural nude et qui se tient droite",
        },
        {
          img:
            "https://static.percko.com/uploads/fb9f2298-b9a1-43a9-a719-453489c4a357.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/2fc229ab-975e-4f28-a38e-3f3b3228a224.jpg",
          alt:
            "Une femme assise voûtée puis une femme assise droite grâce au t-shirt postural nude",
        },
        {
          img:
            "https://static.percko.com/uploads/9194c505-f869-48cd-80fc-2e0b2a012799.jpg",
          alt: "Une femme de profil droit portant un t-shirt postural nude",
        },
      ],
    },
  },
}

export const LyneUp2BImageSliders = {
  fr: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/25166551-4bb5-447b-a190-b18c92c6228a.jpg",
        alt: "A man and woman wearing the Lyne UP back support vest",
      },
      {
        img:
          "https://static.percko.com/uploads/3a7b5dc3-fac7-4089-9dc6-63f328eadf11.jpeg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/38a8e86e-586b-4d41-8cbd-63d97dc59c16.jpg",
        alt:
          "Un homme et une femme qui porte chacun un modèle du t-shirt postural ",
      },
      {
        img:
          "https://static.percko.com/uploads/f0db2b1d-9cb1-47d0-a7c1-6cb6155703ca.jpg",
        alt:
          "Un homme et une femme qui porte la 2ème version du t-shirt postural",
      },
      {
        img:
          "https://static.percko.com/uploads/a8313adb-ef0b-4ca7-8fa4-5db0ede380f3.jpg",
        alt:
          "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
      },
      {
        img:
          "https://static.percko.com/uploads/ec4b4dc2-746b-493c-a631-990efdcfd057.jpg",
        alt:
          "Un homme avec un t-shirt qui corrige la posture derrière un bureau",
      },
      {
        img:
          "https://static.percko.com/uploads/d4976120-0af6-45ed-815e-a7f51ae43114.jpg",
        alt: "Photo des modèles femme en blanc et de l`homme en noir",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/1bce4a2c-f075-4fa9-b092-b8e707423da0.jpg",
          alt: "A man and woman wearing the Lyne UP back support vest",
        },
        {
          img:
            "https://static.percko.com/uploads/f2e65d54-6ca2-48fe-84aa-d82b54fc35b5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d15e7b57-5cbc-490c-92de-c1d14914fb18.jpg",
          alt:
            "Un homme et une femme qui porte chacun un modèle du t-shirt postural",
        },
        {
          img:
            "https://static.percko.com/uploads/f903a4be-77cd-4423-b5b8-c9aae7d73397.jpg",
          alt:
            "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        },
        {
          img:
            "https://static.percko.com/uploads/6e52c8be-39df-4c33-8255-1db267a6cd19.jpg",
          alt:
            "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        },
        {
          img:
            "https://static.percko.com/uploads/4d90c25a-ccd3-472c-9a34-dde0c873ac1b.jpg",
          alt:
            "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        },
        // {
        //   img:
        //     "https://static.percko.com/uploads/65b82076-c185-4e81-ab8b-67c4749c7b1e.jpg",
        //   alt:
        //     "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        // },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/5ee87bfd-a9cf-4536-b7ab-5f80c1024c03.jpg",
          alt:
            "Un homme qui porte un t-shirt nude avec des tenseurs pour soulager le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/a0327519-6743-4555-90fa-eaf00c01970e.jpg",
          alt:
            "Un homme avec un t-shirt postural nude et une chemise par-dessus",
        },
        {
          img:
            "https://static.percko.com/uploads/fa2b9db3-fda3-48ee-9fb6-007aa102d8b5.jpg",
          alt:
            "Un homme avec une chemise qui recouvre un t-shirt nude discret sous les vêtements",
        },
        {
          img:
            "https://static.percko.com/uploads/855676a1-248c-4f30-93ea-9121a2de4883.jpg",
          alt:
            "Un homme assis qui porte un t-shirt nude avec des tenseurs pour soulager son dos",
        },
        {
          img:
            "https://static.percko.com/uploads/e7810e37-2b9a-4ef3-b3bb-3c8fcc896cef.jpg",
          alt: "Une image du t-shirt Lyne UP homme nude de PERCKO vu de face",
        },
        // {
        //   img:
        //     "https://static.percko.com/uploads/65b82076-c185-4e81-ab8b-67c4749c7b1e.jpg",
        //   alt:
        //     "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        // },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/f33460e3-7d7f-47e6-9d7d-c02865313ee1.jpg",
          alt:
            "Une femme qui porte un t-shirt noir avec des tenseurs pour soulager le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/7324df78-db2c-4a7a-b9a6-d346bc8a2cde.jpg",
          alt:
            "Une femme assise de dos qui porte un t-shirt noir avec des tenseurs pour se redresser",
        },
        {
          img:
            "https://static.percko.com/uploads/497ecdef-92c9-4565-b45a-9e3c8ec556d5.jpg",
          alt:
            "Une femme de profil portant un t-shirt postural noir et qui se tient droite",
        },
        {
          img:
            "https://static.percko.com/uploads/2c91cc35-d6fc-45b3-b609-9fbcfa788df2.jpg",
          alt:
            "Une femme de dos avec un t-shirt composé de tenseurs et qui se tient droite",
        },
        {
          img:
            "https://static.percko.com/uploads/a1f69734-4f32-44ce-b5b3-1d0a55e6c099.jpg",
          alt:
            "A before and after photo of a woman correcting her posture with the Lyne UP posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/78a4569b-9f03-4066-b000-ce71f9e3c5d0.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest from semi-profile",
        },
        {
          img:
            "https://static.percko.com/uploads/1227e714-8a8c-4a77-a0e8-718e94776cab.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest looking at the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/70015094-7c1e-4c4e-8376-3d38ebc540db.jpg",
          alt: "Une femme de profil droit portant un t-shirt postural noir",
        },
        {
          img:
            "https://static.percko.com/uploads/d1717c84-50c7-4c75-a62a-f17e6f2a3901.jpg",
          alt:
            "A product shot of the women`s black Lyne UP back support T-shirt",
        },
        // {
        //   img:
        //     "https://static.percko.com/uploads/eb169df5-afec-466c-bf69-80c621c8a08e.jpg",
        //   alt:
        //     "A product shot of the women`s black Lyne UP back support T-shirt",
        // },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/94ee2b0a-b354-4692-8a2d-d9eb6db7c899.jpg",
          alt:
            "Une femme de dos qui porte un t-shirt nude avec des tenseurs pour redresser son dos",
        },
        {
          img:
            "https://static.percko.com/uploads/8f2cf1ff-a790-4709-a328-0247fcc9dbbc.jpg",
          alt:
            "Une femme assise de dos qui porte un t-shirt nude avec des tenseurs pour se soulager",
        },
        {
          img:
            "https://static.percko.com/uploads/fc3054d6-2ca7-477a-af22-eb6c25f5727c.jpg",
          alt:
            "Une femme de face avec un tshirt postural nude et qui se tient droite",
        },
        {
          img:
            "https://static.percko.com/uploads/fb9f2298-b9a1-43a9-a719-453489c4a357.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/2fc229ab-975e-4f28-a38e-3f3b3228a224.jpg",
          alt:
            "Une femme assise voûtée puis une femme assise droite grâce au t-shirt postural nude",
        },
        {
          img:
            "https://static.percko.com/uploads/9194c505-f869-48cd-80fc-2e0b2a012799.jpg",
          alt: "Une femme de profil droit portant un t-shirt postural nude",
        },
        {
          img:
            "https://static.percko.com/uploads/5460f47a-a6ce-4c09-a840-83612556bc45.jpg",
          alt:
            "Une femme avec une chemise qui recouvre un t-shirt nude discret sous les vêtements",
        },
        {
          img:
            "https://static.percko.com/uploads/082d8838-8009-465c-9ae0-2e510b5898a2.jpg",
          alt:
            "Une image avec un zoom sur les attaches du t-shirt Lyne UP nude pour femme",
        },
        {
          img:
            "https://static.percko.com/uploads/8708caba-3ba0-4376-b530-9215e470eaad.jpg",
          alt:
            "Une femme portantun t-shirt correcteur de posture nude avec un zoom sur l`ction des tenseurs",
        },
        {
          img:
            "https://static.percko.com/uploads/16de2a1e-deb6-488c-9b32-1968ded264f2.jpg",
          alt: "Une image du t-shirt Lyne UP femme nude de PERCKO vu de face",
        },
        // {
        //   img:
        //     "https://static.percko.com/uploads/eb169df5-afec-466c-bf69-80c621c8a08e.jpg",
        //   alt:
        //     "A product shot of the women`s black Lyne UP back support T-shirt",
        // },
      ],
    },
  },
  en: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/94ee2b0a-b354-4692-8a2d-d9eb6db7c899.jpg",
        alt: "A woman turned away wearing the Lyne UP back vest",
      },
      {
        img:
          "https://static.percko.com/uploads/8f2cf1ff-a790-4709-a328-0247fcc9dbbc.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/fc3054d6-2ca7-477a-af22-eb6c25f5727c.jpg",
        alt:
          "A woman from the front with a nude postural tshirt and who stands straight",
      },
      {
        img:
          "https://static.percko.com/uploads/fb9f2298-b9a1-43a9-a719-453489c4a357.jpg",
        alt:
          "Une femme de dos avec un zoom sur les tenseurs du t-shirt postural nude",
      },
      {
        img:
          "https://static.percko.com/uploads/2fc229ab-975e-4f28-a38e-3f3b3228a224.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9194c505-f869-48cd-80fc-2e0b2a012799.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/5460f47a-a6ce-4c09-a840-83612556bc45.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/082d8838-8009-465c-9ae0-2e510b5898a2.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8708caba-3ba0-4376-b530-9215e470eaad.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/16de2a1e-deb6-488c-9b32-1968ded264f2.jpg",
        alt: "",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/79cc769d-dc4e-4919-b6d5-219c5b5b4e8e.jpg",
          alt:
            "Un homme qui porte un t-shirt noir avec des tenseurs pour soulager le dos",
        },
        {
          img:
            "https://static.percko.com/uploads/65162584-5928-4751-afdb-1c7e6f7086b5.jpg",
          alt: "A man sitting at desk wearing Lyne UP back support vest",
        },
        {
          img:
            "https://static.percko.com/uploads/19e594dc-174b-4a1a-9a97-bcbfe1fee9db.jpg",
          alt:
            "A man sitting down demonstrating how the Lyne UP helps correct posture",
        },
        {
          img:
            "https://static.percko.com/uploads/d7b0b649-e93f-4cce-bded-3526ad465325.jpg",
          alt: "A man from behind wearing the Lyne UP PERCKO posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/d2623518-7523-4a2a-a3d0-f802f749dfd3.jpg",
          alt: "A product shot of men`s black posture correcting vest",
        },
        {
          img:
            "https://static.percko.com/uploads/5a260b0d-19c8-4314-a019-346f89e2815b.jpg",
          alt: "A close up of the clips on the Lyne UP back support vest",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/5ee87bfd-a9cf-4536-b7ab-5f80c1024c03.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/a0327519-6743-4555-90fa-eaf00c01970e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fa2b9db3-fda3-48ee-9fb6-007aa102d8b5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/855676a1-248c-4f30-93ea-9121a2de4883.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e7810e37-2b9a-4ef3-b3bb-3c8fcc896cef.jpg",
          alt: "",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/f33460e3-7d7f-47e6-9d7d-c02865313ee1.jpg",
          alt:
            "A woman wearing the black women`s Lyne UP vest smiling at the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/7324df78-db2c-4a7a-b9a6-d346bc8a2cde.jpg",
          alt:
            "A woman sitting down demonstrating how the Lyne UP helps correct posture",
        },
        {
          img:
            "https://static.percko.com/uploads/497ecdef-92c9-4565-b45a-9e3c8ec556d5.jpg",
          alt:
            "A woman wearing the black women`s Lyne UP vest looking away from the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/2c91cc35-d6fc-45b3-b609-9fbcfa788df2.jpg",
          alt: "A woman wearing the black women`s Lyne UP posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/a1f69734-4f32-44ce-b5b3-1d0a55e6c099.jpg",
          alt:
            "Une femme assise qui est voûtée puis qui se redresse grâce à un t-shirt posturalt",
        },
        {
          img:
            "https://static.percko.com/uploads/78a4569b-9f03-4066-b000-ce71f9e3c5d0.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest from semi-profile",
        },
        {
          img:
            "https://static.percko.com/uploads/1227e714-8a8c-4a77-a0e8-718e94776cab.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest looking at the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/70015094-7c1e-4c4e-8376-3d38ebc540db.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest from profile",
        },
        {
          img:
            "https://static.percko.com/uploads/d1717c84-50c7-4c75-a62a-f17e6f2a3901.jpg",
          alt: "Une image du t-shirt Lyne UP femme noir de PERCKO vu de face",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/94ee2b0a-b354-4692-8a2d-d9eb6db7c899.jpg",
          alt: "A woman turned away wearing the Lyne UP back vest",
        },
        {
          img:
            "https://static.percko.com/uploads/8f2cf1ff-a790-4709-a328-0247fcc9dbbc.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fc3054d6-2ca7-477a-af22-eb6c25f5727c.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fb9f2298-b9a1-43a9-a719-453489c4a357.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/2fc229ab-975e-4f28-a38e-3f3b3228a224.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/9194c505-f869-48cd-80fc-2e0b2a012799.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/5460f47a-a6ce-4c09-a840-83612556bc45.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/082d8838-8009-465c-9ae0-2e510b5898a2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8708caba-3ba0-4376-b530-9215e470eaad.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/16de2a1e-deb6-488c-9b32-1968ded264f2.jpg",
          alt: "",
        },
      ],
    },
  },
  de: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/25166551-4bb5-447b-a190-b18c92c6228a.jpg",
        alt: "A man and woman wearing the Lyne UP back support vest",
      },
      {
        img:
          "https://static.percko.com/uploads/38a8e86e-586b-4d41-8cbd-63d97dc59c16.jpg",
        alt:
          "A man and woman facing different ways wearing the Lyne UP back vest",
      },
      {
        img:
          "https://static.percko.com/uploads/f0db2b1d-9cb1-47d0-a7c1-6cb6155703ca.jpg",
        alt:
          "A woman and man facing different ways wearing the Lyne UP posture corrector",
      },
      {
        img:
          "https://static.percko.com/uploads/a8313adb-ef0b-4ca7-8fa4-5db0ede380f3.jpg",
        alt:
          "A woman sitting in an office wearing the Lyne UP demonstrating it`s discretion",
      },
      {
        img:
          "https://static.percko.com/uploads/ec4b4dc2-746b-493c-a631-990efdcfd057.jpg",
        alt:
          "A man sitting in an office wearing the Lyne UP demonstrating it`s discretion",
      },
      {
        img:
          "https://static.percko.com/uploads/d4976120-0af6-45ed-815e-a7f51ae43114.jpg",
        alt:
          "A men`s and women`s product photo of the Lyne UP back support vest",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/79cc769d-dc4e-4919-b6d5-219c5b5b4e8e.jpg",
          alt: "A man turned away wearing the Lyne UP back vest",
        },
        {
          img:
            "https://static.percko.com/uploads/65162584-5928-4751-afdb-1c7e6f7086b5.jpg",
          alt: "A man sitting at desk wearing Lyne UP back support vest",
        },
        {
          img:
            "https://static.percko.com/uploads/19e594dc-174b-4a1a-9a97-bcbfe1fee9db.jpg",
          alt:
            "A man sitting down demonstrating how the Lyne UP helps correct posture",
        },
        {
          img:
            "https://static.percko.com/uploads/d7b0b649-e93f-4cce-bded-3526ad465325.jpg",
          alt: "A man from behind wearing the Lyne UP PERCKO posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/d2623518-7523-4a2a-a3d0-f802f749dfd3.jpg",
          alt: "A product shot of men`s black posture correcting vest",
        },
        {
          img:
            "https://static.percko.com/uploads/5a260b0d-19c8-4314-a019-346f89e2815b.jpg",
          alt: "A close up of the clips on the Lyne UP back support vest",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/5ee87bfd-a9cf-4536-b7ab-5f80c1024c03.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/a0327519-6743-4555-90fa-eaf00c01970e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fa2b9db3-fda3-48ee-9fb6-007aa102d8b5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/855676a1-248c-4f30-93ea-9121a2de4883.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e7810e37-2b9a-4ef3-b3bb-3c8fcc896cef.jpg",
          alt: "",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/f33460e3-7d7f-47e6-9d7d-c02865313ee1.jpg",
          alt:
            "A woman wearing the black women`s Lyne UP vest smiling at the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/7324df78-db2c-4a7a-b9a6-d346bc8a2cde.jpg",
          alt:
            "A woman sitting down demonstrating how the Lyne UP helps correct posture",
        },
        {
          img:
            "https://static.percko.com/uploads/497ecdef-92c9-4565-b45a-9e3c8ec556d5.jpg",
          alt:
            "A woman wearing the black women`s Lyne UP vest looking away from the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/2c91cc35-d6fc-45b3-b609-9fbcfa788df2.jpg",
          alt: "A woman wearing the black women`s Lyne UP posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/a1f69734-4f32-44ce-b5b3-1d0a55e6c099.jpg",
          alt:
            "A before and after photo of a woman correcting her posture with the Lyne UP posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/78a4569b-9f03-4066-b000-ce71f9e3c5d0.jpg",
          alt:
            "Une femme de dos avec un zoom sur les tenseurs du t-shirt postural noir",
        },
        {
          img:
            "https://static.percko.com/uploads/1227e714-8a8c-4a77-a0e8-718e94776cab.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest looking at the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/70015094-7c1e-4c4e-8376-3d38ebc540db.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest from profile",
        },
        {
          img:
            "https://static.percko.com/uploads/d1717c84-50c7-4c75-a62a-f17e6f2a3901.jpg",
          alt:
            "A product shot of the women`s black Lyne UP back support T-shirt",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/94ee2b0a-b354-4692-8a2d-d9eb6db7c899.jpg",
          alt: "A woman turned away wearing the Lyne UP back vest",
        },
        {
          img:
            "https://static.percko.com/uploads/8f2cf1ff-a790-4709-a328-0247fcc9dbbc.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fc3054d6-2ca7-477a-af22-eb6c25f5727c.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fb9f2298-b9a1-43a9-a719-453489c4a357.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/2fc229ab-975e-4f28-a38e-3f3b3228a224.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/9194c505-f869-48cd-80fc-2e0b2a012799.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/5460f47a-a6ce-4c09-a840-83612556bc45.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/082d8838-8009-465c-9ae0-2e510b5898a2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8708caba-3ba0-4376-b530-9215e470eaad.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/16de2a1e-deb6-488c-9b32-1968ded264f2.jpg",
          alt: "",
        },
      ],
    },
  },
  es: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/25166551-4bb5-447b-a190-b18c92c6228a.jpg",
        alt: "A man and woman wearing the Lyne UP back support vest",
      },
      {
        img:
          "https://static.percko.com/uploads/38a8e86e-586b-4d41-8cbd-63d97dc59c16.jpg",
        alt:
          "A man and woman facing different ways wearing the Lyne UP back vest",
      },
      {
        img:
          "https://static.percko.com/uploads/f0db2b1d-9cb1-47d0-a7c1-6cb6155703ca.jpg",
        alt:
          "A woman and man facing different ways wearing the Lyne UP posture corrector",
      },
      {
        img:
          "https://static.percko.com/uploads/a8313adb-ef0b-4ca7-8fa4-5db0ede380f3.jpg",
        alt:
          "A woman sitting in an office wearing the Lyne UP demonstrating it`s discretion",
      },
      {
        img:
          "https://static.percko.com/uploads/ec4b4dc2-746b-493c-a631-990efdcfd057.jpg",
        alt:
          "A man sitting in an office wearing the Lyne UP demonstrating it`s discretion",
      },
      {
        img:
          "https://static.percko.com/uploads/d4976120-0af6-45ed-815e-a7f51ae43114.jpg",
        alt:
          "A men`s and women`s product photo of the Lyne UP back support vest",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/79cc769d-dc4e-4919-b6d5-219c5b5b4e8e.jpg",
          alt: "A man turned away wearing the Lyne UP back vest",
        },
        {
          img:
            "https://static.percko.com/uploads/65162584-5928-4751-afdb-1c7e6f7086b5.jpg",
          alt: "A man sitting at desk wearing Lyne UP back support vest",
        },
        {
          img:
            "https://static.percko.com/uploads/19e594dc-174b-4a1a-9a97-bcbfe1fee9db.jpg",
          alt:
            "A man sitting down demonstrating how the Lyne UP helps correct posture",
        },
        {
          img:
            "https://static.percko.com/uploads/d7b0b649-e93f-4cce-bded-3526ad465325.jpg",
          alt: "A man from behind wearing the Lyne UP PERCKO posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/d2623518-7523-4a2a-a3d0-f802f749dfd3.jpg",
          alt: "A product shot of men`s black posture correcting vest",
        },
        {
          img:
            "https://static.percko.com/uploads/5a260b0d-19c8-4314-a019-346f89e2815b.jpg",
          alt: "A close up of the clips on the Lyne UP back support vest",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/5ee87bfd-a9cf-4536-b7ab-5f80c1024c03.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/a0327519-6743-4555-90fa-eaf00c01970e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fa2b9db3-fda3-48ee-9fb6-007aa102d8b5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/855676a1-248c-4f30-93ea-9121a2de4883.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e7810e37-2b9a-4ef3-b3bb-3c8fcc896cef.jpg",
          alt: "",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/f33460e3-7d7f-47e6-9d7d-c02865313ee1.jpg",
          alt:
            "A woman wearing the black women`s Lyne UP vest smiling at the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/7324df78-db2c-4a7a-b9a6-d346bc8a2cde.jpg",
          alt:
            "A woman sitting down demonstrating how the Lyne UP helps correct posture",
        },
        {
          img:
            "https://static.percko.com/uploads/497ecdef-92c9-4565-b45a-9e3c8ec556d5.jpg",
          alt:
            "A woman wearing the black women`s Lyne UP vest looking away from the camera",
        },
        {
          img:
            "https://static.percko.com/uploads/2c91cc35-d6fc-45b3-b609-9fbcfa788df2.jpg",
          alt: "A woman wearing the black women`s Lyne UP posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/a1f69734-4f32-44ce-b5b3-1d0a55e6c099.jpg",
          alt:
            "A before and after photo of a woman correcting her posture with the Lyne UP posture vest",
        },
        {
          img:
            "https://static.percko.com/uploads/78a4569b-9f03-4066-b000-ce71f9e3c5d0.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest from semi-profile",
        },
        {
          img:
            "https://static.percko.com/uploads/1227e714-8a8c-4a77-a0e8-718e94776cab.jpg",
          alt:
            "Une femme de face avec un tshirt postural noir et qui se tient droite",
        },
        {
          img:
            "https://static.percko.com/uploads/70015094-7c1e-4c4e-8376-3d38ebc540db.jpg",
          alt:
            "A woman wearing the black Lyne UP PERCKO posture vest from profile",
        },
        {
          img:
            "https://static.percko.com/uploads/d1717c84-50c7-4c75-a62a-f17e6f2a3901.jpg",
          alt:
            "A product shot of the women`s black Lyne UP back support T-shirt",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/94ee2b0a-b354-4692-8a2d-d9eb6db7c899.jpg",
          alt: "A woman turned away wearing the Lyne UP back vest",
        },
        {
          img:
            "https://static.percko.com/uploads/8f2cf1ff-a790-4709-a328-0247fcc9dbbc.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fc3054d6-2ca7-477a-af22-eb6c25f5727c.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/fb9f2298-b9a1-43a9-a719-453489c4a357.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/2fc229ab-975e-4f28-a38e-3f3b3228a224.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/9194c505-f869-48cd-80fc-2e0b2a012799.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/5460f47a-a6ce-4c09-a840-83612556bc45.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/082d8838-8009-465c-9ae0-2e510b5898a2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8708caba-3ba0-4376-b530-9215e470eaad.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/16de2a1e-deb6-488c-9b32-1968ded264f2.jpg",
          alt: "",
        },
      ],
    },
  },
}

export const ImageSliders = {
  Default: [
    {
      img:
        "https://static.percko.com/uploads/375a11bd-10c5-4af7-9cd2-00c2cd1500bf.jpeg",
      alt: "",
    },
    {
      img:
        "https://static.percko.com/uploads/d228d942-08cf-425f-9808-18404b161501.jpg",
      alt: "",
    },
    {
      img:
        "https://static.percko.com/uploads/477cf10c-0310-4cea-9e71-3d3bcac68c43.jpg",
      alt: "",
    },
    {
      img:
        "https://static.percko.com/uploads/fe264338-cad8-400b-b7ab-a1d143feb6aa.jpg",
      alt: "",
    },
  ],
  Male: {
    BLACK: [
      {
        img:
          "https://static.percko.com/uploads/44d19800-a62d-483f-a5b5-973b5dccb2a6.jpeg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f299a94a-fe64-49ce-a00e-12bf2397fd9c.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/27419dd6-ca33-44ba-9939-96c9bcc162ed.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0b1e248b-ad0a-4586-a023-3c33c59cb4b7.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/330ff27b-7fab-4ebe-b3d4-d08c9f15d30d.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e8aece02-8ffd-49eb-b8fe-efca4002cac8.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/151594b0-e8c8-4f30-827f-8a61830c34f7.jpg",
        alt: "",
      },
    ],
    NUDE: [
      {
        img:
          "https://static.percko.com/uploads/5fccfeb3-2e9c-431b-800d-ac53666b3481.jpeg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/69e7b502-b38f-464f-b5a7-8d92927df573.jpeg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/da5a5747-69be-4f74-ad86-39bd43dc90ae.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/3487bbe4-2462-4ac0-8989-ecebfbd3fbe3.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/81c14447-02f5-4a84-be43-e6851bc07a96.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/c3b500d4-52b0-4d47-a5b3-769d4da4c4d2.jpg",
        alt: "",
      },
    ],
  },
  Female: {
    BLACK: [
      {
        img:
          "https://static.percko.com/uploads/9df88eaf-c61b-4bd3-b896-27ef5bf9690d.jpeg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9a28db5c-1b5e-4e96-b4a5-f284d4306767.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e7765ce2-9d68-411c-830e-dde3909298cd.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/ddcdf3bc-caf5-4bd8-9cd7-6f1728c31e02.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/bb5b4aca-ae61-4885-978e-d97b7c931266.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/c6d29b06-41f5-41e7-b045-a290e1b3ef09.jpg",
        alt: "",
      },
    ],
    NUDE: [
      {
        img:
          "https://static.percko.com/uploads/9df88eaf-c61b-4bd3-b896-27ef5bf9690d.jpeg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9a28db5c-1b5e-4e96-b4a5-f284d4306767.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e7765ce2-9d68-411c-830e-dde3909298cd.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/ddcdf3bc-caf5-4bd8-9cd7-6f1728c31e02.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/bb5b4aca-ae61-4885-978e-d97b7c931266.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/c6d29b06-41f5-41e7-b045-a290e1b3ef09.jpg",
        alt: "",
      },
    ],
  },
  fr: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/c8cc4423-1b1f-428d-ac7b-0c3e190a3286.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8d26316c-cb14-4ecf-bee1-103b685949f2.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/03686eab-f820-4ade-a3b7-4e1e37ef36ab.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/13f436ac-43a8-425c-a809-bf5d4d9ce1cd.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0a6e7608-3b7a-46d5-91bd-3273832a3731.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/34f59275-8bed-4835-ac4b-d93c95e726b7.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0d9b8c42-d131-4587-ba77-f8d8941acca1.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8892123e-a065-41ee-8faa-ca580a5bdf41.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9d95b5b5-da69-4951-ac17-3c1b7e24955a.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/b88cb9a5-148c-403d-aad8-718118f88316.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e39f4b64-ba6a-4a2d-bc2f-29741380087d.jpg",
        alt: "",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/bc389483-b9ff-49c9-beb6-cfbc9647246b.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/3a6b4abe-967a-4448-bc82-eead0f7aaa30.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ddb72fe1-a17a-4aa3-b338-e670eda96bb2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/913be24e-f30c-4884-b3fb-5e3d7ef502dc.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d33ad19e-8ec1-46b2-9aba-22dbdea1ab71.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/7d03033b-4818-4ad2-97cd-37e561101344.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/b7dd3ad1-341d-43fd-acd4-90e026c73f88.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e861bb9e-d33c-4980-8bda-2e07b9ceea70.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8533625e-9d9b-46ef-80e4-b3be2866b88a.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/cabc857a-97b5-49b4-8c7c-f591ce7d0dd3.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c0d44c8a-b79b-484a-8c6d-6f8aa46dc3c9.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d42dedf6-b130-4679-b0bd-8268f651af52.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/1ea9f293-2cc4-4f2e-a0d3-321c71fb386e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/407ae3d4-9398-40e4-8c71-55c8c74fbb48.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62a3f1af-be43-492c-9749-12201816adc2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/271c77b0-0136-45fe-bf66-baf3050082cf.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0b2b7372-8e46-48e8-8fd9-9b9bea325952.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/470cc64e-3048-4b6a-a265-7bb0a90c2aff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/24d50f9c-24e0-4097-b162-fb98ea463ab7.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ef4a10cd-b947-42b2-afe8-d06bbf0d8e98.jpg",
          alt: "",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/5d7fd280-a5f2-4c59-9544-826270e639ca.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/b998850b-e4d6-4f0d-9763-b24d838f8db2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/712886f1-0838-4a1f-bc8a-7524bb600c5b.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/864a6b07-14f6-41e3-9941-3911a2de8299.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/59e13501-1ee7-4fa4-84ff-c17910e86225.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/19e3187c-1483-4b65-abb8-56e277170fa0.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/41b99b5d-10e3-4d74-9046-5292ae600a11.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/de0193f1-707d-4709-a3ca-3c991a9322f5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8cf39195-b333-4661-a89d-0c4f31815ae1.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/424a6ab3-7b15-40ae-8b8d-78f8687e242b.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c6c3ae04-430f-4be3-ba6c-85a6b414829f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/eae09f29-83e0-4e6e-b3f1-c225047a466f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/165f8b7c-9e4f-4c83-aabb-801776a31aa1.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/6b764cd8-b9e0-4c20-9b4e-e3981c410815.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/7c8c1ead-c1bd-48aa-84a3-e6b8a2b0092e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/99720b59-8e46-4cd0-8b09-64d66625a4ab.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0c8296a6-cb3b-45d7-a1a9-5de8770d08ff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/4c009718-07d5-4e62-8e90-98769fbeced2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/f8a26ebb-a332-4a11-96c3-4bb081f076f5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/45441131-5feb-4056-af5c-cad63f2e379b.jpg",
          alt: "",
        },
      ],
    },
  },
  en: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/c8cc4423-1b1f-428d-ac7b-0c3e190a3286.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8d26316c-cb14-4ecf-bee1-103b685949f2.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/03686eab-f820-4ade-a3b7-4e1e37ef36ab.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/13f436ac-43a8-425c-a809-bf5d4d9ce1cd.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0a6e7608-3b7a-46d5-91bd-3273832a3731.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/ba70b250-5ead-4f45-90f9-5b2dcd02fc5a.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6e838585-d995-421a-b7c1-2948161777e6.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8892123e-a065-41ee-8faa-ca580a5bdf41.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9d95b5b5-da69-4951-ac17-3c1b7e24955a.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/b88cb9a5-148c-403d-aad8-718118f88316.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e39f4b64-ba6a-4a2d-bc2f-29741380087d.jpg",
        alt: "",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/bc389483-b9ff-49c9-beb6-cfbc9647246b.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/3a6b4abe-967a-4448-bc82-eead0f7aaa30.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ddb72fe1-a17a-4aa3-b338-e670eda96bb2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/913be24e-f30c-4884-b3fb-5e3d7ef502dc.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d33ad19e-8ec1-46b2-9aba-22dbdea1ab71.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/2cbe08b2-9361-42f0-830a-0d7ab11c68ae.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/b7dd3ad1-341d-43fd-acd4-90e026c73f88.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e861bb9e-d33c-4980-8bda-2e07b9ceea70.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8533625e-9d9b-46ef-80e4-b3be2866b88a.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/cabc857a-97b5-49b4-8c7c-f591ce7d0dd3.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c0d44c8a-b79b-484a-8c6d-6f8aa46dc3c9.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d42dedf6-b130-4679-b0bd-8268f651af52.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/1ea9f293-2cc4-4f2e-a0d3-321c71fb386e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/407ae3d4-9398-40e4-8c71-55c8c74fbb48.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62a3f1af-be43-492c-9749-12201816adc2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/1e79b40d-f285-4821-83bb-8701c5cc97ac.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0b2b7372-8e46-48e8-8fd9-9b9bea325952.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/470cc64e-3048-4b6a-a265-7bb0a90c2aff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/24d50f9c-24e0-4097-b162-fb98ea463ab7.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ef4a10cd-b947-42b2-afe8-d06bbf0d8e98.jpg",
          alt: "",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/c0d44c8a-b79b-484a-8c6d-6f8aa46dc3c9.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d42dedf6-b130-4679-b0bd-8268f651af52.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/1ea9f293-2cc4-4f2e-a0d3-321c71fb386e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/407ae3d4-9398-40e4-8c71-55c8c74fbb48.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62a3f1af-be43-492c-9749-12201816adc2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/1e79b40d-f285-4821-83bb-8701c5cc97ac.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0b2b7372-8e46-48e8-8fd9-9b9bea325952.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/470cc64e-3048-4b6a-a265-7bb0a90c2aff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/24d50f9c-24e0-4097-b162-fb98ea463ab7.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ef4a10cd-b947-42b2-afe8-d06bbf0d8e98.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c6c3ae04-430f-4be3-ba6c-85a6b414829f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/eae09f29-83e0-4e6e-b3f1-c225047a466f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/165f8b7c-9e4f-4c83-aabb-801776a31aa1.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/6b764cd8-b9e0-4c20-9b4e-e3981c410815.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/7c8c1ead-c1bd-48aa-84a3-e6b8a2b0092e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/232c9f8d-2682-4fbb-93fc-d5588d523b6f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0c8296a6-cb3b-45d7-a1a9-5de8770d08ff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/4c009718-07d5-4e62-8e90-98769fbeced2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/f8a26ebb-a332-4a11-96c3-4bb081f076f5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/45441131-5feb-4056-af5c-cad63f2e379b.jpg",
          alt: "",
        },
      ],
    },
  },
  de: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/c8cc4423-1b1f-428d-ac7b-0c3e190a3286.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8d26316c-cb14-4ecf-bee1-103b685949f2.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/03686eab-f820-4ade-a3b7-4e1e37ef36ab.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/13f436ac-43a8-425c-a809-bf5d4d9ce1cd.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0a6e7608-3b7a-46d5-91bd-3273832a3731.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8892123e-a065-41ee-8faa-ca580a5bdf41.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9d95b5b5-da69-4951-ac17-3c1b7e24955a.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/b88cb9a5-148c-403d-aad8-718118f88316.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e39f4b64-ba6a-4a2d-bc2f-29741380087d.jpg",
        alt: "",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/bc389483-b9ff-49c9-beb6-cfbc9647246b.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/3a6b4abe-967a-4448-bc82-eead0f7aaa30.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ddb72fe1-a17a-4aa3-b338-e670eda96bb2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/913be24e-f30c-4884-b3fb-5e3d7ef502dc.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d33ad19e-8ec1-46b2-9aba-22dbdea1ab71.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/b7dd3ad1-341d-43fd-acd4-90e026c73f88.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e861bb9e-d33c-4980-8bda-2e07b9ceea70.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8533625e-9d9b-46ef-80e4-b3be2866b88a.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/cabc857a-97b5-49b4-8c7c-f591ce7d0dd3.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c0d44c8a-b79b-484a-8c6d-6f8aa46dc3c9.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d42dedf6-b130-4679-b0bd-8268f651af52.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/1ea9f293-2cc4-4f2e-a0d3-321c71fb386e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/407ae3d4-9398-40e4-8c71-55c8c74fbb48.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62a3f1af-be43-492c-9749-12201816adc2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0b2b7372-8e46-48e8-8fd9-9b9bea325952.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/470cc64e-3048-4b6a-a265-7bb0a90c2aff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/24d50f9c-24e0-4097-b162-fb98ea463ab7.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ef4a10cd-b947-42b2-afe8-d06bbf0d8e98.jpg",
          alt: "",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/5d7fd280-a5f2-4c59-9544-826270e639ca.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/b998850b-e4d6-4f0d-9763-b24d838f8db2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/712886f1-0838-4a1f-bc8a-7524bb600c5b.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/864a6b07-14f6-41e3-9941-3911a2de8299.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/59e13501-1ee7-4fa4-84ff-c17910e86225.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/41b99b5d-10e3-4d74-9046-5292ae600a11.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/de0193f1-707d-4709-a3ca-3c991a9322f5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8cf39195-b333-4661-a89d-0c4f31815ae1.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/424a6ab3-7b15-40ae-8b8d-78f8687e242b.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c6c3ae04-430f-4be3-ba6c-85a6b414829f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/eae09f29-83e0-4e6e-b3f1-c225047a466f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/165f8b7c-9e4f-4c83-aabb-801776a31aa1.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/6b764cd8-b9e0-4c20-9b4e-e3981c410815.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/7c8c1ead-c1bd-48aa-84a3-e6b8a2b0092e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0c8296a6-cb3b-45d7-a1a9-5de8770d08ff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/4c009718-07d5-4e62-8e90-98769fbeced2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/f8a26ebb-a332-4a11-96c3-4bb081f076f5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/45441131-5feb-4056-af5c-cad63f2e379b.jpg",
          alt: "",
        },
      ],
    },
  },
  es: {
    Default: [
      {
        img:
          "https://static.percko.com/uploads/c8cc4423-1b1f-428d-ac7b-0c3e190a3286.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8d26316c-cb14-4ecf-bee1-103b685949f2.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/03686eab-f820-4ade-a3b7-4e1e37ef36ab.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/13f436ac-43a8-425c-a809-bf5d4d9ce1cd.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0a6e7608-3b7a-46d5-91bd-3273832a3731.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8892123e-a065-41ee-8faa-ca580a5bdf41.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9d95b5b5-da69-4951-ac17-3c1b7e24955a.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/b88cb9a5-148c-403d-aad8-718118f88316.jpg",
        alt: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e39f4b64-ba6a-4a2d-bc2f-29741380087d.jpg",
        alt: "",
      },
    ],
    Male: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/bc389483-b9ff-49c9-beb6-cfbc9647246b.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/3a6b4abe-967a-4448-bc82-eead0f7aaa30.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ddb72fe1-a17a-4aa3-b338-e670eda96bb2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/913be24e-f30c-4884-b3fb-5e3d7ef502dc.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d33ad19e-8ec1-46b2-9aba-22dbdea1ab71.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/b7dd3ad1-341d-43fd-acd4-90e026c73f88.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/e861bb9e-d33c-4980-8bda-2e07b9ceea70.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8533625e-9d9b-46ef-80e4-b3be2866b88a.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/cabc857a-97b5-49b4-8c7c-f591ce7d0dd3.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c0d44c8a-b79b-484a-8c6d-6f8aa46dc3c9.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/d42dedf6-b130-4679-b0bd-8268f651af52.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/1ea9f293-2cc4-4f2e-a0d3-321c71fb386e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/407ae3d4-9398-40e4-8c71-55c8c74fbb48.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/62a3f1af-be43-492c-9749-12201816adc2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0b2b7372-8e46-48e8-8fd9-9b9bea325952.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/470cc64e-3048-4b6a-a265-7bb0a90c2aff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/24d50f9c-24e0-4097-b162-fb98ea463ab7.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/ef4a10cd-b947-42b2-afe8-d06bbf0d8e98.jpg",
          alt: "",
        },
      ],
    },
    Female: {
      BLACK: [
        {
          img:
            "https://static.percko.com/uploads/5d7fd280-a5f2-4c59-9544-826270e639ca.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/b998850b-e4d6-4f0d-9763-b24d838f8db2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/712886f1-0838-4a1f-bc8a-7524bb600c5b.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/864a6b07-14f6-41e3-9941-3911a2de8299.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/59e13501-1ee7-4fa4-84ff-c17910e86225.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/41b99b5d-10e3-4d74-9046-5292ae600a11.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/de0193f1-707d-4709-a3ca-3c991a9322f5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/8cf39195-b333-4661-a89d-0c4f31815ae1.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/424a6ab3-7b15-40ae-8b8d-78f8687e242b.jpg",
          alt: "",
        },
      ],
      NUDE: [
        {
          img:
            "https://static.percko.com/uploads/c6c3ae04-430f-4be3-ba6c-85a6b414829f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/eae09f29-83e0-4e6e-b3f1-c225047a466f.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/165f8b7c-9e4f-4c83-aabb-801776a31aa1.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/6b764cd8-b9e0-4c20-9b4e-e3981c410815.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/7c8c1ead-c1bd-48aa-84a3-e6b8a2b0092e.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/0c8296a6-cb3b-45d7-a1a9-5de8770d08ff.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/4c009718-07d5-4e62-8e90-98769fbeced2.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/f8a26ebb-a332-4a11-96c3-4bb081f076f5.jpg",
          alt: "",
        },
        {
          img:
            "https://static.percko.com/uploads/45441131-5feb-4056-af5c-cad63f2e379b.jpg",
          alt: "",
        },
      ],
    },
  },
}

export const LyneUpComboImage = {
  Default: [
    "https://static.percko.com/uploads/87945203-679f-49df-990a-6688a9aa6693.png",
    "https://static.percko.com/uploads/cd27ca55-0fb5-4f24-aa14-7526e4338d18.png",
  ],
  Male: {
    BLACK: [
      "https://static.percko.com/uploads/64bb680b-f4e9-4844-8523-fe5ae2c9dc44.png",
      "https://static.percko.com/uploads/3dcbff99-5ea3-4130-aa09-9b6336117276.png",
    ],
    NUDE: [
      "https://static.percko.com/uploads/38f6e779-0d57-42a6-987a-4277379df4f7.png",
      "https://static.percko.com/uploads/df0f5070-54f4-4f52-89e7-a3069bf2acdc.png",
    ],
    BLUE: [
      "https://static.percko.com/uploads/1ee0e552-6ce2-4644-ae93-efa7949ff279.png",
      "https://static.percko.com/uploads/d121bf60-a7c5-47a2-bb45-757bee1c5589.png",
    ],
    "BLACK MICRO-PERFORATED": [
      "https://static.percko.com/uploads/f40b8db8-1fb4-4e52-a513-42611291f92b.png",
      "https://static.percko.com/uploads/fb206fda-1db1-4a25-912b-cf5e5719211a.png",
    ],
  },
  Female: {
    BLACK: [
      "https://static.percko.com/uploads/87945203-679f-49df-990a-6688a9aa6693.png",
      "https://static.percko.com/uploads/cd27ca55-0fb5-4f24-aa14-7526e4338d18.png",
    ],
    NUDE: [
      "https://static.percko.com/uploads/325ed1f3-8ad2-43b8-a386-69cb5d998bbe.png",
      "https://static.percko.com/uploads/24d4ac69-510e-4984-8a17-74a84c325476.png",
    ],
    BLUE: [
      "https://static.percko.com/uploads/86b62e57-886c-4fc4-864d-f7dc8432bce8.png",
      "https://static.percko.com/uploads/a9ef24d6-c7ec-47af-8e5d-5dd77a2d29e2.png",
    ],
    "BLACK MICRO-PERFORATED": [
      "https://static.percko.com/uploads/fa6b413e-9bdf-46f3-8f47-a3c59ef1e235.png",
      "https://static.percko.com/uploads/79ebe9d9-8355-4eea-8ec0-c3bd48ed9969.png",
    ],
  },
}

export const LyneComboImage = {
  Default: [
    "https://static.percko.com/uploads/3d34d8dd-027b-4daa-a678-0015a2d5f04b.png",
    "https://static.percko.com/uploads/be54806e-be02-40ad-8449-5f8dfe1f2610.png",
  ],
  Male: {
    Noir: [
      "https://static.percko.com/uploads/aac9dcb2-3b8a-49bb-bd65-61e5d58a4496.png",
      "https://static.percko.com/uploads/a03378a3-6842-4fc2-b1ff-dee81edf3204.png",
    ],
    Sable: [
      "https://static.percko.com/uploads/19b92725-950e-4845-b38f-c0bd12a5049f.png",
      "https://static.percko.com/uploads/606300a0-aa26-41a4-ab93-39a7e00b9a9f.png",
    ],
    Gris: [
      "https://static.percko.com/uploads/faa47c40-fd44-44f3-bcd5-9c2682bc234d.png",
      "https://static.percko.com/uploads/d94ea6d6-6bf7-4f09-9c77-ddeb3defb5a4.png",
    ],
  },
  Female: {
    Noir: [
      "https://static.percko.com/uploads/3d34d8dd-027b-4daa-a678-0015a2d5f04b.png",
      "https://static.percko.com/uploads/be54806e-be02-40ad-8449-5f8dfe1f2610.png",
    ],
    "Rose poudré": [
      "https://static.percko.com/uploads/0127cd8e-90e5-4244-adb3-d65a2c566116.png",
      "https://static.percko.com/uploads/9135a5c6-d6a5-467f-9bd8-b9f37e322a34.png",
    ],
  },
}

export const LyneFemaleComboImage = {
  Default: [
    "https://static.percko.com/uploads/47a5d255-ac56-4a0c-b246-4bec4f03ff4f.png",
    "https://static.percko.com/uploads/9ae8d8b4-1ec6-4ed1-8139-434a762dec31.png",
  ],
  Female: {
    Noir: [
      "https://static.percko.com/uploads/47a5d255-ac56-4a0c-b246-4bec4f03ff4f.png",
      "https://static.percko.com/uploads/9ae8d8b4-1ec6-4ed1-8139-434a762dec31.png",
    ],
    "Rose poudré": [
      "https://static.percko.com/uploads/96d2384b-601a-408c-9088-3b1c63121049.png",
      "https://static.percko.com/uploads/268a6af0-3b50-4eae-8b31-81f6a2e3706b.png",
    ],
  },
}

export const LyneFitComboImage = {
  Default: [
    "https://static.percko.com/uploads/7226a237-00ef-48d2-b0c4-32909df7da1a.png",
    "https://static.percko.com/uploads/20c62f61-c34b-43ec-aa66-a53521e53cc9.png",
  ],
  Male: {
    Noir: [
      "https://static.percko.com/uploads/b521e910-5b4c-4134-a43e-9a610f6a0ca2.png",
      "https://static.percko.com/uploads/39be9f1b-a2d4-40ce-a4d0-38881951e5fa.png",
    ],
    "Noir MP": [
      "https://static.percko.com/uploads/b521e910-5b4c-4134-a43e-9a610f6a0ca2.png",
      "https://static.percko.com/uploads/39be9f1b-a2d4-40ce-a4d0-38881951e5fa.png",
    ],
  },
  Female: {
    Noir: [
      "https://static.percko.com/uploads/7226a237-00ef-48d2-b0c4-32909df7da1a.png",
      "https://static.percko.com/uploads/20c62f61-c34b-43ec-aa66-a53521e53cc9.png",
    ],
    "Noir MP": [
      "https://static.percko.com/uploads/7226a237-00ef-48d2-b0c4-32909df7da1a.png",
      "https://static.percko.com/uploads/20c62f61-c34b-43ec-aa66-a53521e53cc9.png",
    ],
  },
}

export const LyneHomeComboImage = {
  Default: [
    "https://static.percko.com/uploads/cf553b63-77ff-4f8c-8e5f-38d1a60cb876.png",
    "https://static.percko.com/uploads/c12d915d-2107-4df1-8155-3035c7b3daac.png",
  ],
  Male: {
    Noir: [
      "https://static.percko.com/uploads/cf553b63-77ff-4f8c-8e5f-38d1a60cb876.png",
      "https://static.percko.com/uploads/c12d915d-2107-4df1-8155-3035c7b3daac.png",
    ],
  },
  Female: {
    Noir: [
      "https://static.percko.com/uploads/cf553b63-77ff-4f8c-8e5f-38d1a60cb876.png",
      "https://static.percko.com/uploads/c12d915d-2107-4df1-8155-3035c7b3daac.png",
    ],
  },
}

export const LyneUpNewImageSliders = {
  Male: {
    BLACK: [
      {
        img:
          "https://static.percko.com/uploads/5209ec37-0bac-420d-b8cb-c087e473f8c2.jpg",
        alt: "A man and woman wearing the Lyne UP back support vest",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/ef24aedc-6ba2-4d9a-b0e3-6ed7241308ca.jpg",
        alt: "",
        imageText: "7 tailles spécifiques",
      },
      {
        img:
          "https://static.percko.com/uploads/3b8c66ef-95f5-450f-b8bd-4a95bee03bae.jpg",
        alt:
          "Un homme et une femme qui porte chacun un modèle du t-shirt postural",
        imageText: "Attaches réglables sur 3 niveaux",
      },
      {
        img:
          "https://static.percko.com/uploads/a651d6f4-ea8f-4c32-b1ac-c459e33afe35.jpg",
        alt:
          "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0b139bf7-90e9-48c1-9c3e-dba27cb972dd.jpg",
        alt:
          "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e01c7fe9-8bac-4328-8337-5808de81665b.jpg",
        alt:
          "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        imageText: "",
      },
    ],
    NUDE: [
      {
        img:
          "https://static.percko.com/uploads/653e9c7b-40d0-40ff-8283-fbc3b2b18b9b.jpg",
        alt:
          "Un homme qui porte un t-shirt nude avec des tenseurs pour soulager le dos",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/5be426cf-39b1-4ff2-9012-934f051a605c.jpg",
        alt: "Un homme avec un t-shirt postural nude et une chemise par-dessus",
        imageText: "7 tailles spécifiques",
      },
      {
        img:
          "https://static.percko.com/uploads/a78f2992-2eb4-4b12-abf1-d93dcf6704d1.jpg",
        alt:
          "Un homme avec une chemise qui recouvre un t-shirt nude discret sous les vêtements",
        imageText: "Attaches réglables sur 3 niveaux",
      },
      {
        img:
          "https://static.percko.com/uploads/e731cd9e-2162-4582-ba08-eee81039851b.jpg",
        alt:
          "Un homme assis qui porte un t-shirt nude avec des tenseurs pour soulager son dos",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d86763a4-8e61-419f-b788-e886854ae2b4.jpg",
        alt: "Une image du t-shirt Lyne UP homme nude de PERCKO vu de face",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8aab6761-a021-4ef2-b263-c9ad91bc4e5e.jpg",
        alt:
          "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        imageText: "",
      },
    ],
  },
  Female: {
    BLACK: [
      {
        img:
          "https://static.percko.com/uploads/dd4eec9e-8666-4b90-9647-8df6b65b7677.jpg",
        alt:
          "Une femme qui porte un t-shirt noir avec des tenseurs pour soulager le dos",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/1472af12-680c-4b99-bb22-901a0efd1262.jpg",
        alt:
          "Une femme assise de dos qui porte un t-shirt noir avec des tenseurs pour se redresser",
        imageText: "7 tailles spécifiques",
      },
      {
        img:
          "https://static.percko.com/uploads/93cc31c5-d801-4275-9a4a-1afca5900681.jpg",
        alt:
          "Une femme de profil portant un t-shirt postural noir et qui se tient droite",
        imageText: "Attaches réglables sur 3 niveaux",
      },
      {
        img:
          "https://static.percko.com/uploads/45798a8e-70ab-421f-9e24-ecf15538f9ba.jpg",
        alt:
          "Une femme de dos avec un t-shirt composé de tenseurs et qui se tient droite",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/64727fef-f946-4caa-842c-c1713bb07abe.jpg",
        alt:
          "A before and after photo of a woman correcting her posture with the Lyne UP posture vest",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/aa9c961f-e296-4756-8680-1ecc27167157.jpg",
        alt:
          "A woman wearing the black Lyne UP PERCKO posture vest from semi-profile",
        imageText: "",
      },
    ],
    NUDE: [
      {
        img:
          "https://static.percko.com/uploads/ce9a047d-6c3b-44eb-b43e-4cd7b7cac1fc.jpg",
        alt:
          "Une femme de dos qui porte un t-shirt nude avec des tenseurs pour redresser son dos",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8f16721d-1fec-4906-8229-a42501264704.jpg",
        alt:
          "Une femme assise de dos qui porte un t-shirt nude avec des tenseurs pour se soulager",
        imageText: "7 tailles spécifiques",
      },
      {
        img:
          "https://static.percko.com/uploads/95f426ec-2ce8-4f2d-9114-004f9bc88af1.jpg",
        alt:
          "Une femme de face avec un tshirt postural nude et qui se tient droite",
        imageText: "Attaches réglables sur 3 niveaux",
      },
      {
        img:
          "https://static.percko.com/uploads/449ee4a5-13bd-44e7-aaf5-85db46cb9be5.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e5fb3d62-ad6c-4531-b554-7aad317463db.jpg",
        alt:
          "Une femme assise voûtée puis une femme assise droite grâce au t-shirt postural nude",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/da122ea7-04c1-428d-b307-1dab51c1d13b.jpg",
        alt: "Une femme de profil droit portant un t-shirt postural nude",
        imageText: "",
      },
    ],
  },
}

export const LyneUpImageSliders = {
  Male: {
    Noir: [
      {
        img:
          "https://static.percko.com/uploads/bdfd3b48-be84-4201-a8e1-c60507dec6a6.jpg",
        alt: "A man and woman wearing the Lyne UP back support vest",
        imageText: "Mannequin de 1,76m, porte la taille H1.P2",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/72621f1b-29ed-4ac6-993f-7beae141b8b4.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/8d73d482-26f5-401a-8192-9d3423d147c0.jpg",
        alt:
          "Un homme et une femme qui porte chacun un modèle du t-shirt postural",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6f250623-30f0-4fd7-aef7-8769a9000b39.jpg",
        alt:
          "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_discret_homme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/216f3040-3c4c-4b20-bd52-5477cdcc3015.jpg",
        alt:
          "Un homme et une femme qui porte la 2ème version du t-shirt postural",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/75d2ca38-a991-456e-a92f-60b411d47ec0.jpg",
        alt:
          "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        imageText: "",
        videoUrl: "",
      },
    ],
    Sable: [
      {
        img:
          "https://static.percko.com/uploads/0671541b-1e79-4423-afd1-67107cd4e5c0.jpg",
        alt:
          "Un homme qui porte un t-shirt nude avec des tenseurs pour soulager le dos",
        imageText: "Mannequin de 1,76m, porte la taille H1.P2",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/23e113e8-1e0b-4fc2-8ed5-9998168f4be3.jpg",
        alt: "Un homme avec un t-shirt postural nude et une chemise par-dessus",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f55c7fff-7ce1-4f57-be4a-2fa4e462fa73.jpg",
        alt:
          "Un homme avec une chemise qui recouvre un t-shirt nude discret sous les vêtements",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6f250623-30f0-4fd7-aef7-8769a9000b39.jpg",
        alt:
          "Un homme assis qui porte un t-shirt nude avec des tenseurs pour soulager son dos",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_discret_homme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/15acb984-e5ff-4a77-bc9c-9a2179a93b0c.jpg",
        alt: "Une image du t-shirt Lyne UP homme nude de PERCKO vu de face",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d1a77519-5801-4b00-84b1-ae0a0290d1c3.jpg",
        alt:
          "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        imageText: "",
        videoUrl: "",
      },
    ],
    Gris: [
      {
        img:
          "https://static.percko.com/uploads/9b2baca7-346c-4e7d-bd86-617617a21803.jpg",
        alt:
          "Un homme qui porte un t-shirt nude avec des tenseurs pour soulager le dos",
        imageText: "Mannequin de 1,76m, porte la taille H1.P2",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/90bb1b71-91fd-40fd-9f79-43ea2647cab9.jpg",
        alt: "Un homme avec un t-shirt postural nude et une chemise par-dessus",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/5f734cfe-0137-4e96-8567-b65f097ea85d.jpg",
        alt:
          "Un homme avec une chemise qui recouvre un t-shirt nude discret sous les vêtements",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6f250623-30f0-4fd7-aef7-8769a9000b39.jpg",
        alt:
          "Un homme assis qui porte un t-shirt nude avec des tenseurs pour soulager son dos",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_discret_homme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/37a008bd-a15c-4117-8d6d-939e38857698.jpg",
        alt: "Une image du t-shirt Lyne UP homme nude de PERCKO vu de face",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/ffac8dde-1a4b-4136-81fa-e8ded186da07.jpg",
        alt:
          "Une femme avec un t-shirt qui corrige sa posture derrière un bureau",
        imageText: "",
        videoUrl: "",
      },
    ],
  },
  Female: {
    Noir: [
      {
        img:
          "https://static.percko.com/uploads/f6f2eb36-320c-40d7-ab59-5f454b94482d.jpg",
        alt:
          "Une femme assise de dos qui porte un t-shirt noir avec des tenseurs pour se redresser",
        imageText: "Mannequin de 1,72m, porte la taille H2.P2",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/1773bc2b-23c1-4cc7-ac07-28a3e07f0fa7.jpg",
        alt:
          "Une femme qui porte un t-shirt noir avec des tenseurs pour soulager le dos",
        imageText: "Système de tenseurs<br/>adaptés aux grandes tailles",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6371c239-831c-4fdb-ac86-b210465e5963.jpg",
        alt:
          "Une femme de profil portant un t-shirt postural noir et qui se tient droite",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/acba0bfe-270f-497e-abfb-04bb503bd0ba.jpg",
        alt:
          "Une femme de dos avec un t-shirt composé de tenseurs et qui se tient droite",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_discret_femme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/e86a73e8-1b6a-4c42-bf5b-61ea7fa0e13a.jpg",
        alt:
          "A before and after photo of a woman correcting her posture with the Lyne UP posture vest",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/44c92131-370b-4b93-a212-5be8eafa9eb9.jpg",
        alt:
          "A woman wearing the black Lyne UP PERCKO posture vest from semi-profile",
        imageText: "",
        videoUrl: "",
      },
    ],
    "Rose poudré": [
      {
        img:
          "https://static.percko.com/uploads/b0f7a4b9-4c44-43c7-bdd4-6811ae9ca4c0.jpg",
        alt:
          "Une femme de dos qui porte un t-shirt nude avec des tenseurs pour redresser son dos",
        imageText: "Mannequin de 1,77m, porte la taille H2.P4",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/39f10ffe-7f41-4455-bfc4-84b7402e9adb.jpg",
        alt:
          "Une femme assise de dos qui porte un t-shirt nude avec des tenseurs pour se soulager",
        imageText: "Système de tenseurs<br/>adaptés aux grandes tailles",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d41c85da-4c4c-4e62-b596-bf6dc52af96b.jpg",
        alt:
          "Une femme de face avec un tshirt postural nude et qui se tient droite",
        imageText: "Mannequin de 1,72m, porte la taille H2.P2",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/acba0bfe-270f-497e-abfb-04bb503bd0ba.jpg",
        alt: "",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_discret_femme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/59a3b817-cfbd-474c-94cc-0788084f2f68.jpeg",
        alt:
          "Une femme assise voûtée puis une femme assise droite grâce au t-shirt postural nude",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0fbc12d9-e97e-4efa-9f68-bbb11b8dd591.jpg",
        alt: "Une femme de profil droit portant un t-shirt postural nude",
        imageText: "",
        videoUrl: "",
      },
    ],
  },
}

export const LyneUpCollarRondImageSliders = {
  Female: {
    Noir: [
      {
        img:
          "https://static.percko.com/uploads/6f515ee6-ec86-4a7f-8552-078198737195.jpg",
        alt:
          "Une femme assise de dos qui porte un t-shirt noir avec des tenseurs pour se redresser",
        imageText: "Mannequin de 1,72m, porte la taille H2.P2",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/51b80205-a2e6-4f23-990a-0955e60ef398.jpg",
        alt:
          "Une femme qui porte un t-shirt noir avec des tenseurs pour soulager le dos",
        imageText: "Système de tenseurs<br/>adaptés aux grandes tailles",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/fcfa072e-79bc-4877-bd69-0d1297b0abef.jpg",
        alt:
          "Une femme de profil portant un t-shirt postural noir et qui se tient droite",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/acba0bfe-270f-497e-abfb-04bb503bd0ba.jpg",
        alt:
          "Une femme de dos avec un t-shirt composé de tenseurs et qui se tient droite",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_discret_femme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/c7c51a64-0a30-4f91-928a-f45f12fa75bc.jpg",
        alt:
          "A before and after photo of a woman correcting her posture with the Lyne UP posture vest",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0222a101-5ff3-4703-8b8d-cce27ac6ff6e.jpg",
        alt:
          "A woman wearing the black Lyne UP PERCKO posture vest from semi-profile",
        imageText: "",
        videoUrl: "",
      },
    ],
    "Rose poudré": [
      {
        img:
          "https://static.percko.com/uploads/af44d3b7-46af-41dc-aeee-671f041f3878.jpg",
        alt:
          "Une femme de dos qui porte un t-shirt nude avec des tenseurs pour redresser son dos",
        imageText: "Mannequin de 1,77m, porte la taille H2.P4",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/9df6b60f-dadd-4534-ac45-634895807b69.jpg",
        alt:
          "Une femme assise de dos qui porte un t-shirt nude avec des tenseurs pour se soulager",
        imageText: "Système de tenseurs<br/>adaptés aux grandes tailles",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/6f38b15a-0641-4ee5-aad6-f1433b37812a.jpg",
        alt:
          "Une femme de face avec un tshirt postural nude et qui se tient droite",
        imageText: "Mannequin de 1,72m, porte la taille H2.P2",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/acba0bfe-270f-497e-abfb-04bb503bd0ba.jpg",
        alt: "",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_t-shirt_discret_femme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/2c777b2b-d322-4122-8af4-377c6c267c06.jpeg",
        alt:
          "Une femme assise voûtée puis une femme assise droite grâce au t-shirt postural nude",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/0fbc12d9-e97e-4efa-9f68-bbb11b8dd591.jpg",
        alt: "Une femme de profil droit portant un t-shirt postural nude",
        imageText: "",
        videoUrl: "",
      },
    ],
  },
}

export const CapitalizeFirstLetter = string => {
  return string[0].toUpperCase() + string.slice(1)
}

export const OutOfStockItems = {
  eur: {
    lyneup: {
      Male: {
        BLACK: [],
        NUDE: [],
      },
      Female: {
        BLACK: [],
        NUDE: [],
      },
    },
    lynefit: {
      Male: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: [],
      },
      Female: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: [],
      },
    },
    lynehome: {
      Male: {
        BLUE: [],
      },
      Female: {
        BLUE: [],
      },
    },
  },
  gbp: {
    lyneup: {
      Male: {
        BLACK: [],
        NUDE: [],
      },
      Female: {
        BLACK: [],
        NUDE: [],
      },
    },
    lynefit: {
      Male: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: ["L"],
      },
      Female: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: ["M"],
      },
    },
    lynehome: {
      Male: {
        BLUE: [],
      },
      Female: {
        BLUE: ["M"],
      },
    },
  },
  cad: {
    lyneup: {
      Male: {
        BLACK: [],
        NUDE: [],
      },
      Female: {
        BLACK: [],
        NUDE: [],
      },
    },
    lynefit: {
      Male: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: [],
      },
      Female: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: [],
      },
    },
    lynehome: {
      Male: {
        BLUE: [],
      },
      Female: {
        BLUE: [],
      },
    },
  },
  usd: {
    lyneup: {
      Male: {
        BLACK: [],
        NUDE: [],
      },
      Female: {
        BLACK: [],
        NUDE: [],
      },
    },
    lynefit: {
      Male: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: [],
      },
      Female: {
        "BLACK MICRO-PERFORATED": [],
        BLUE: [],
      },
    },
    lynehome: {
      Male: {
        BLUE: [],
      },
      Female: {
        BLUE: [],
      },
    },
  },
}
