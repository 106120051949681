import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { CountryDropOptions } from "../../../../utils/CountryDetectionDropHelper"

const countries = CountryDropOptions()

const CountryDetectionSelectorWrapper = styled.div`
  padding: 0px 20px;
  display: flex;
  margin-top: 10px;

  @media (max-width: 550px) {
    padding: 0px;
    width: 100%;
  }
`

const Select = styled.select`
  width: 100%;
  min-height: 30px;
  border-radius: 5px;
  color: #fff;
  background: #444;
  cursor: pointer;
  min-width: 200px;
  background: #fff;
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  color: #262626;
`

const ContinueButton = styled.div`
  background: #fff;
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  color: #262626;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
  font-weight: bold;
`

const CountryDetectionSelector = ({
  intl,
  handleStore,
  country,
  redirectStore,
}) => {
  return (
    <CountryDetectionSelectorWrapper>
      <Select onChange={e => handleStore(e.target.value)} value={country}>
        {countries.map((item, index) => (
          <option key={index.toString()} value={item.country_code}>
            {item.title}
          </option>
        ))}
      </Select>
      <ContinueButton
        onClick={() => {
          redirectStore()
        }}
      >
        OK
      </ContinueButton>
    </CountryDetectionSelectorWrapper>
  )
}

export default injectIntl(CountryDetectionSelector)
