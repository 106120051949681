import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Modal from "styled-react-modal"
import { formSubmitEventScript } from "../../utils/additionalScriptHelpers"

const ModalWrapper = Modal.styled`
  width: 875px;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 15px;
  transition: transform .5s,opacity .3s;
  transform: translateZ(0);
  @media(max-width:600px){
      width:80%;
      height:98%;
  }
`

const RenewEmailText = styled.div`
  font-family: "GothamLight";
  padding: 8px 16px;
  font-size: 14px;
`

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  height: 100%;
  padding: 8px 16px;

  flex-flow: row wrap;
  font-family: "GothamLight";

  > * {
    display: block;
  }

  > label {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    :after {
      content: " *";
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
    font-size: 12px;

    label {
      margin-left: 10px;
      font-weight: bold;
    }
  }

  input[type="checkbox"] {
    margin: 0;
  }

  input[type="text"],
  input[type="password"] {
    box-sizing: border-box;
    background-color: #ede9e8;
    border-radius: 0px;
    border: 0;
    color: #0e1116;
    padding: 0 0.75em;
    /* height: 2.507em; */
    font-size: 0.97em;
    border-radius: 0;
    max-width: 100%;
    width: 100%;
    vertical-align: middle;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: color 0.3s, border 0.3s, background-color 0.3s, opacity 0.3s;
    height: 34px;
  }

  p {
    text-transform: uppercase;
    margin: 0;
    font-size: 14px;
  }

  button {
    background-color: #262626;
    color: #fff;

    border-color: rgba(0, 0, 0, 0.05);
    position: relative;

    display: inline-block;
    text-transform: uppercase;
    font-size: 0.97em;
    letter-spacing: 0.03em;
    touch-action: none;
    cursor: pointer;
    font-weight: normal;
    text-align: center;
    text-decoration: none;

    margin: 20px 1em 0 0;
    text-shadow: none;
    line-height: 2.4em;
    min-height: 2.5em;
    padding: 0 1.2em;
    font-size: 14px;
  }

  a {
    font-size: 12px;
    color: #334862;
    text-decoration: none;
    letter-spacing: 0;
    margin: 16px 0;
    cursor: pointer;

    :hover {
      color: #262626;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const CenterWrapper = styled.div`
  height: 80%;
  width: 1px;
  height: 320px;
  background: #ececec;
  @media (max-width: 600px) {
    height: 1px;
    width: 90%;
  }
`

const RightWrapper = styled.div`
  background-color: green;
  flex: 1;
  padding: 16px 8px;
`

const LoginModal = ({
  intl,
  setProfile,
  toggleModal,
  handleLogin,
  handleSignup,
  forgetPassowrd,
  onEscapeKeydown,
  ...props
}) => {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [email, setemail] = useState()
  const [newPass, setnewPass] = useState()
  const [sentEmail, setsentEmail] = useState(false)
  const [userSignUpError, setuserSignUpError] = useState("")
  const [userLogInError, setuserLogInError] = useState("")
  const handleOnClick = () => {
    if (!username) {
      setuserLogInError(intl.formatMessage({ id: "empty__email" }))
    } else if (!password) {
      setuserLogInError(intl.formatMessage({ id: "empty__password" }))
    } else {
      handleLogin(username, password, d => {
        if (d.data.customerAccessTokenCreate.customerUserErrors.length === 0) {
          toggleModal()
          formSubmitEventScript("login", intl.locale)
        } else {
          d.data.customerAccessTokenCreate.customerUserErrors.map(item => {
            if (item.code === "TAKEN") {
              setuserLogInError(intl.formatMessage({ id: "email_taken" }))
            } else if (item.code === "TOO_SHORT") {
              setuserLogInError(intl.formatMessage({ id: "pass_too_short" }))
            } else if (item.code === "UNIDENTIFIED_CUSTOMER") {
              setuserLogInError(
                intl.formatMessage({ id: "unidentified_customer" })
              )
            } else {
              setuserLogInError(intl.formatMessage({ id: "email_invalid" }))
            }
          })
        }
      })
    }
  }
  const handleSignUpButton = () => {
    if (!email) {
      setuserSignUpError(intl.formatMessage({ id: "empty__email" }))
    } else if (!newPass) {
      setuserSignUpError(intl.formatMessage({ id: "empty__password" }))
    } else {
      handleSignup(email, newPass, d => {
        if (d.data.customerCreate.customerUserErrors.length === 0) {
          formSubmitEventScript("sign_up", intl.locale)
          setsentEmail(d)
        } else {
          d.data.customerCreate.customerUserErrors.map(item => {
            if (item.code === "TAKEN") {
              setuserSignUpError(intl.formatMessage({ id: "email_taken" }))
            } else if (item.code === "TOO_SHORT") {
              setuserSignUpError(intl.formatMessage({ id: "pass_too_short" }))
            } else if (item.code === "CUSTOMER_DISABLED") {
              setuserSignUpError(
                intl
                  .formatMessage({ id: "disabled_customer" })
                  .replace("email_id", email)
              )
            } else {
              setuserSignUpError(intl.formatMessage({ id: "email_invalid" }))
            }
          })
        }
      })
    }
  }

  return (
    <ModalWrapper {...props}>
      {/* <RenewEmailText>
        {intl.formatMessage({ id: "login_popup_renew_password_text" })}
      </RenewEmailText> */}
      <BottomWrapper>
        <ContentWrapper>
          <p>{intl.formatMessage({ id: "login" })}</p>
          <label htmlFor="username">
            {intl.formatMessage({ id: "username_text" })}
          </label>
          <input
            type="text"
            name="username"
            onChange={e => setUsername(e.target.value)}
          />

          <label htmlFor="password">
            {intl.formatMessage({ id: "password_text" })}
          </label>
          <input
            type="password"
            name="password"
            onChange={e => setPassword(e.target.value)}
          />

          <div>
            <input type="checkbox" name="remember" />
            <label htmlFor="remember">
              {intl.formatMessage({ id: "remember_me_text" })}
            </label>
          </div>

          <button onClick={handleOnClick}>
            {intl.formatMessage({ id: "login" })}
          </button>

          {userLogInError && (
            <div style={{ color: "red" }}>{userLogInError}</div>
          )}
          <Link to="/forgot-password/" onClick={onEscapeKeydown}>
            {intl.formatMessage({ id: "lost_passowrd" })}
          </Link>
        </ContentWrapper>
        <CenterWrapper />
        <ContentWrapper>
          <p>{intl.formatMessage({ id: "register_text" })}</p>
          <label htmlFor="username">
            {intl.formatMessage({ id: "email_text" })}
          </label>
          <input
            type="text"
            name="username"
            onChange={e => setemail(e.target.value)}
          />

          <label htmlFor="password">
            {intl.formatMessage({ id: "password_text" })}
          </label>
          <input
            type="password"
            name="password"
            onChange={e => setnewPass(e.target.value)}
          />

          <button onClick={() => handleSignUpButton()}>
            {intl.formatMessage({ id: "register_text" })}
          </button>

          {sentEmail && (
            <div style={{ color: "green" }}>
              {intl
                .formatMessage({ id: "createUser" })
                .replace("email_id", email)}
            </div>
          )}

          {userSignUpError && (
            <div style={{ color: "red" }}>{userSignUpError}</div>
          )}
        </ContentWrapper>
      </BottomWrapper>
    </ModalWrapper>
  )
}

export default injectIntl(LoginModal)
