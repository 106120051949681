import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import Popover, { ArrowContainer } from "react-tiny-popover"
import { injectIntl } from "gatsby-plugin-intl"
import { dropCurrencyOptions } from "../../../../utils/langDropHelper"
import { isBrowser } from "../../../../context/ApolloContext"
import { useLocation } from "@reach/router"
import { handleCurrRedirects } from "../../../../utils/langCurrSeclectorHelper"

const languages = dropCurrencyOptions()

const Language = ({ flag, alt, title, locale, curr, lang, ...props }) => {
  const [newLocation, setnewLocation] = useState("")
  const { pathname, origin, search } = useLocation()

  useEffect(() => {
    let newLocation = handleCurrRedirects({
      newCurr: curr,
      pathname,
      origin,
      search,
    })
    setnewLocation(newLocation)
  }, [pathname])

  return (
    <a
      {...props}
      href={newLocation}
      onClick={() => {
        if (isBrowser) {
          localStorage.setItem("favCurr", curr || "eur")
          sessionStorage.setItem("selectedCountry", curr || "eur")
        }
      }}
    >
      <span>
        {title ||
          process.env.GATSBY_CURRENCY + " " + process.env.GATSBY_CURRENCY_TYPE}
      </span>
    </a>
  )
}

const CurrencySelectedWrapper = styled(Language)`
  display: flex;
  width: auto;
  min-width: 80px;
  justify-content: flex-start;
  align-content: center;
  color: ${props => props.theme.colors.white.grey};
  background: #000;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 2px;
  padding: 8px 16px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  color: #cecece;

  :hover {
    color: #cecece;
  }

  img {
    margin-right: 5px;
    margin-bottom: 0;
  }

  span {
    width: auto;
  }
`

const LanguageWrapper = styled(CurrencySelectedWrapper)`
  background: #fff;
  color: ${props => props.theme.colors.black.light};
  :hover {
    color: ${props => props.theme.colors.black.light};
    background-color: #eee;
  }
`
const Languages = ({ onMouseLeave, langLocale }) => (
  <div onMouseLeave={onMouseLeave}>
    {languages.map(
      (lang, index) =>
        lang.lang.includes(langLocale) && (
          <LanguageWrapper key={index.toString()} {...lang} />
        )
    )}
  </div>
)

const PopoverWrapper = styled(Popover)`
  top: 27px !important;
  color: ${props => props.theme.colors.black.light};
  > :first-of-type {
    background-color: #fff !important;
  }
`

const CurrencySelector = ({ intl }) => {
  const locale = process.env.GATSBY_CURRENCY_TYPE || "eur"
  const lang = languages.find(lang => lang.curr === locale)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <PopoverWrapper
      disableReposition
      isOpen={isOpen}
      position={["bottom"]}
      //   content={Languages}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={"#fff"}
          arrowSize={7}
          arrowStyle={{ opacity: 1 }}
        >
          <Languages
            onMouseLeave={() => setIsOpen(false)}
            langLocale={intl.locale}
          />
        </ArrowContainer>
      )}
      onClickOutside={() => setIsOpen(false)}
    >
      <CurrencySelectedWrapper onMouseEnter={() => setIsOpen(true)} {...lang} />
    </PopoverWrapper>
  )
}

export default injectIntl(CurrencySelector)
