import { isBrowser } from "../context/ApolloContext"
let currEnv = process.env.GATSBY_ACTIVE_ENV || "general"

export const getLocalStorageData = fieldName => {
  if (isBrowser) {
    if (typeof JSON.parse(localStorage.getItem(fieldName)) === "object") {
      return JSON.parse(localStorage.getItem(fieldName))
    }
    return localStorage.getItem(fieldName)
  } else return
}

export const setCustomerDetails = (token, value) => {
  if (isBrowser) {
    let data = { ...getLocalStorageData("customerDetails") } || {}
    if (token === "customerAccessToken") {
      if (data && data[currEnv] && data[currEnv]["customerData"]) {
        data[currEnv]["customerAccessToken"] = value
      } else {
        data[currEnv] = {}
        data[currEnv]["customerAccessToken"] = value
      }
      localStorage.setItem("customerDetails", JSON.stringify(data))
    } else if (token === "customerData") {
      if (data && data[currEnv] && data[currEnv]["customerAccessToken"]) {
        data[currEnv]["customerData"] = value
      } else {
        data[currEnv] = {}
        data[currEnv]["customerData"] = value
      }
      localStorage.setItem("customerDetails", JSON.stringify(data))
    }
  }
}

export const getCustomerDetailsObj = token => {
  let data = getLocalStorageData("customerDetails") || {}
  if (data && data[currEnv]) {
    if (token === "customerData") {
      return data[currEnv]["customerData"] || null
    } else {
      return data[currEnv]["customerAccessToken"] || null
    }
  } else return
}

export const setCheckoutID = (value, callback) => {
  let data = getLocalStorageData("checkoutID") || {}
  if (isBrowser) {
    data[currEnv] = value
    localStorage.setItem("checkoutID", JSON.stringify(data))
    if (callback) callback()
  }
}

export const getCheckoutID = () => {
  let data = getLocalStorageData("checkoutID")
  if (!data || typeof data === "string") return
  return data[currEnv] || null
}

export const handleLogoutLocalStorage = () => {
  let tempCheckoutID = getLocalStorageData("checkoutID")
  let tempCustomerDetails = getLocalStorageData("customerDetails")
  delete tempCheckoutID[currEnv]
  delete tempCustomerDetails[currEnv]
  if (isBrowser) {
    localStorage.setItem("customerDetails", JSON.stringify(tempCustomerDetails))
    localStorage.setItem("checkoutID", JSON.stringify(tempCheckoutID))
  }
}

export const getSessionId = () => {
  if (isBrowser) {
    return localStorage.getItem("_uetsid")
  }
  return
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

export const getCookie = cname => {
  let name = cname + "="
  let ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}
