import React, { Component } from "react"
import styled from "@emotion/styled"
import { withStoreContext } from "../../../../context/StoreContext"
import { injectIntl } from "gatsby-plugin-intl"
import { getPrice } from "../../../../utils/navigateToCart"

const CartRect = styled.span`
  width: 30px;
  height: 30px;
  border: 2px solid ${props => props.theme.colors.black.base};
  color: ${props => props.theme.colors.black.base};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  font-size: 1em;
  line-height: 1.9em;
  margin: 0.3em 0;

  &:after {
    bottom: 100%;
    height: 8px;
    width: 14px;
    content: " ";
    position: absolute;
    pointer-events: none;
    border: 2px solid ${props => props.theme.colors.black.base};
    border-top-left-radius: 99px;
    border-top-right-radius: 99px;
    border-bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 850px) {
    width: 25px;
    height: 25px;
  }
`

const CartRectContainer = styled.div`
  display: flex;
  height: auto;
  vertical-align: middle;
  position: relative;
  margin-left: 10px;

  @media (max-width: 1200px) {
    align-items: center;
    flex-direction: column;
  }
`

const MiniCart = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${props => props.theme.colors.black.base};
  font-size: 15px;
  align-items: center;
  &:hover {
    cursor: pointer;
    ${CartRect} {
      background: black;
      color: ${props => props.theme.colors.white.base};
      &:after {
        height: 10px;
      }
    }
    border-color: ${props => props.theme.colors.primary.wavii};
    color: ${props => props.theme.colors.primary.wavii};
  }
  height: 100%;
  padding: 10px 0;
  font-size: 0.9em;

  @media (max-width: 1200px) {
    padding: 0;
  }

  @media (max-width: 850px) {
    border: none;
  }
  /* margin-top: 8px; */
`

const CartText = styled.span`
  line-height: 2em;

  @media (max-width: 1200px) {
    display: none;
  }
`

const CartMiniText = styled.span`
  font-size: 10px;
  line-height: 10px;
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`

const CartPrice = styled.span`
  font-size: 18px;
  line-height: 1.5em;

  @media (max-width: 1200px) {
    display: none;
  }
`

const CurrencySymbol = styled.span`
  margin-top: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  font-family: GothamLight, sans-serif;

  @media (max-width: 1200px) {
    display: none;
  }
`

const MCart = ({ storeContext, intl }) => {
  const getQuantity = () => {
    let total = 0
    storeContext.checkout.lineItems.forEach(item => {
      // if (!item.title.includes("E-carte cadeau offerte")) {
      total = total + item.quantity
      // }
    })
    return total
  }

  const getTotalAmount = () => {
    let total = 0
    let giftCardTotal = 0
    // storeContext.checkout.lineItems.forEach(item => {
    //   if (item.title.includes("E-carte cadeau offerte")) {
    //     giftCardTotal += item.variant.price.amount
    //   }
    // })
    // total = storeContext.checkout.subtotalPrice.amount - giftCardTotal
    total = storeContext.checkout.subtotalPrice.amount
    return getPrice(total)
  }

  return (
    <MiniCart>
      <CartText>
        {intl.formatMessage({ id: "basket" })}&nbsp;{"/"}{" "}
      </CartText>
      &nbsp;
      <CurrencySymbol>
        {process.env.GATSBY_CURRENCY_TYPE !== "eur"
          ? process.env.GATSBY_CURRENCY
          : ""}
      </CurrencySymbol>
      <CartPrice>
        {storeContext?.checkout?.subtotalPrice?.amount
          ? getTotalAmount()
          : Number(0).toFixed(0)}
      </CartPrice>
      <CurrencySymbol>
        &nbsp;
        {process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? process.env.GATSBY_CURRENCY
          : ""}
      </CurrencySymbol>
      <CartRectContainer>
        <CartRect>
          {storeContext?.checkout?.lineItems?.length > 0 ? getQuantity() : 0}
        </CartRect>
        <CartMiniText>{intl.formatMessage({ id: "basket" })}</CartMiniText>
      </CartRectContainer>
    </MiniCart>
  )
}
export default injectIntl(withStoreContext(MCart))
