import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"

const MotherStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #262626;
  position: relative;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 30px;
  font-family: "Museo";
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;

  div:first-of-type {
    font-size: 32px;
    line-height: 1.2;
    font-family: "BebasNeueBold";
    font-weight: bold;
    display: flex;
    align-items: center;

    span {
      background: #d7edf6;
      color: #262626;
      font-size: 36px;
      line-height: 1;
      padding: 5px;
      border-radius: 15px 0px;
      margin: 0px 15px;
    }
  }

  div:last-child {
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    font-family: "Museo";
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }
`

const Button = styled(Link)`
  color: #262626;
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 20px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 767px) {
    display: none;
  }

  :hover {
    color: #262626;
  }
`

const MotherFrStickyBanner = ({ intl }) => {
  var ButtonHide = false
  var regex = /(shop|panier|cart|lyneup|lynefit|lynehome)/
  if (isBrowser) {
    ButtonHide = regex.test(window.location.pathname)
  }

  return (
    <MotherStickyBannerWrapper>
      <Container>
        {!ButtonHide && (
          <LinkBox to="/shop/lyneup-test/">
            <LinkButtonTitle>{"J'en profite >"}</LinkButtonTitle>
          </LinkBox>
        )}
        <Text>
          <div>
            EN CE MOMENT <span>-40%</span>
          </div>
          <div>
            sur le{" "}
            <strong>
              2<sup>e</sup> t-shirt
            </strong>
            <br />
            PERCKO*
          </div>
        </Text>
        {!ButtonHide && (
          <Button to="/shop/lyneup-test/">{"J'en profite >"}</Button>
        )}
      </Container>
    </MotherStickyBannerWrapper>
  )
}

export default injectIntl(MotherFrStickyBanner)
