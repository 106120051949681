import React from "react"

export const defaultUserContext = {
  loading: false,
  error: false,
  discount: false,
  profile: {},
  contributor: {},
  customerAccessToken: null,
  handleLogin: () => {},
  handleLogout: () => {},
  updateCustomer: () => {},
  handleSignup: () => {},
  handleForgetPassword: () => {},
  handleResetPassword: () => {},
}

const UserContext = React.createContext(defaultUserContext)

export const withUserContext = Component => {
  return props => (
    <UserContext.Consumer>
      {context => <Component {...props} userContext={context} />}
    </UserContext.Consumer>
  )
}

export default UserContext
