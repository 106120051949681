import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const CartErrorBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  text-align: center;
  background: #262626;
  color: #fff;
  position: relative;
  overflow: hidden;

  @media (max-width: 450px) {
    padding: 8px 10px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextBox = styled.div`
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;

  a {
    text-decoration: underline;
    color: #fff;
  }

  a:hover {
    text-decoration: underline;
    color: #fff;
  }
`

const CartErrorBanner = ({ intl }) => {
  return (
    <CartErrorBannerWrapper id="cart_error_banner">
      <Container>
        {intl.locale === "fr" ? (
          <TextBox>
            Vous ne pouvez pas ajouter vos produits au panier ? Nous résolvons
            actuellement le problème. Merci d’envoyer un mail à{" "}
            <a href="mailto:contact@percko.com">contact@percko.com</a> avec
            comme objet “SITE” et nous vous recontacterons.
          </TextBox>
        ) : (
          <TextBox>
            You cannot add your products to the cart? We are currently resolving
            the issue. Please send an email to{" "}
            <a href="mailto:contact@percko.com">contact@percko.com</a> with the
            subject “SITE,” and we will get back to you.
          </TextBox>
        )}
      </Container>
    </CartErrorBannerWrapper>
  )
}

export default injectIntl(CartErrorBanner)
